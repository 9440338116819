import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeBookmarksActions'

export const fetch = ({ commit }) => {
  t.$log(pageName, ' fetch')
  return new Promise((resolve, reject) => {
    t.$axios.get(t.$consts.GET_BOOKMARKS_MODULES_URL).then((response) => {
      commit('update', response.data.request.modules)
      resolve()
    }).catch((error) => {
      t.$log(pageName, ' fetch Error', error)
      reject(error.response)
    })
  })
}

export const getBookmarks = ({ commit }) => {
  t.$log(pageName, 'bookmarks actions getBookmarks')
  return new Promise((resolve, reject) => {
    t.$axios.get(t.$consts.GET_BOOKMARKS_MODULES_URL).then((response) => {
      t.$log(pageName, 'bookmarks actions getBookmarks Response', response)
      commit('update', response.request.modules)
      resolve()
    }).catch((error) => {
      t.$log(pageName, 'bookmarks actions getBookmarks Error', error)
      reject(error.response)
    })
  })
}

export const bookmarkModule = ({ state, commit }, payload) => {
  t.$log(pageName, 'bookmarks actions bookmarkModule', payload)
  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.TOGGLE_MODULE_BOOKMARK_URL + '/' + payload.moduleId).then((response) => {
      t.$log(pageName, 'bookmarks actions bookmarkModule response', response)
      commit('update', response.request.modules)
      resolve(response)
    }).catch((error) => {
      t.$log(pageName, 'bookmarks actions bookmarkModule error', error)
      reject(error.response)
    })
  })
}

export const bookmarkCourse = ({ state }, payload) => {
  t.$log('bookmarks actions bookmarkCourse', payload)
  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.TOGGLE_COURSE_BOOKMARK_URL + '/' + payload.moduleId).then((response) => {
      t.$log('bookmarks actions bookmarkCourse response', response)
      resolve(response)
    }).catch((error) => {
      t.$log('bookmarks actions bookmarkCourse error', error)
      reject(error.response)
    })
  })
}
