export default {
  is_logging_in: false,
  is_logged_in: false,
  access_token: null,
  expires_in: null,
  refresh_token: null,
  dataView: 'list',
  filterBy: 'Company',
  showSearch: false,
  searchResults: null,
  roles: {},
  user: {
    customer: {},
    departments: [],
    company: {}
  }
}
