export const data = (state) => {
  return state.data
}

export const getByUid = (state) => (categories, uid) => {
  function recursionByUID (arr, categoryUid) {
    let found = null
    for (var i = 0, len = arr.length; i < len; i++) {
      if (arr[i].uid === categoryUid) {
        return arr[i]
      } else if (arr[i].children !== undefined) {
        found = recursionByUID(arr[i].children, categoryUid)
        if (found) {
          return found
        }
      }
    }
  }
  return recursionByUID(categories, uid)
}

export const rootNodes = (state) => (categories) => {
  function recursionForNodes (arr, results) {
    results = results || [{
      label: 'Root', value: 'root'
    }]
    for (var i = 0, len = arr.length; i < len; i++) {
      if (arr[i].children !== undefined && arr[i].children.length >= 1) {
        results.push({
          'label': arr[i].name,
          'value': arr[i].uid
        })
        recursionForNodes(arr[i].children, results)
      }
    }
    return results
  }
  return recursionForNodes(categories)
}

export const getChildrenByUid = (state) => (categories, uid) => {
  function getByUid (arr, categoryUid) {
    let found = null
    for (var i = 0, len = arr.length; i < len; i++) {
      if (arr[i].uid === categoryUid) {
        return arr[i]
      } else if (arr[i].children !== undefined) {
        found = getByUid(arr[i].children, categoryUid)
        if (found) {
          return found
        }
      }
    }
  }
  let results = []
  let category = getByUid(categories, uid)

  if (category.children !== undefined) {
    results = category.children
  } else {
    results = []
  }
  return results
}
