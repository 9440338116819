import { colors } from 'quasar'
const { setBrand } = colors

export default ({ Vue }) => {
  Vue.prototype.$setTheme = (pThemeObj) => {
    if (pThemeObj) {
      Object.entries(pThemeObj.colors).forEach(color => {
        setBrand(color[0], color[1])
      })
    }
  }
}
