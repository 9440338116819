import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeNewsActions'

export const fetch = ({ commit }) => {
  t.$log(pageName, ' fetch')
  return t.$axios.get(`${t.$consts.GET_NEWS_URL}?getAll=true`).then((response) => {
    return response.data
  }).catch((error) => {
    t.$log(pageName, ' fetch Error', error)
    throw error
  })
}

export const add = ({ commit }, payload) => {
  t.$log(pageName, ' add', payload)
  return t.$axios.post(t.$consts.ADD_NEWS_URL, payload).then((response) => {
    return response.data
  }).catch((error) => {
    t.$log(pageName, 'add error', error)
    throw error
  })
}

export const remove = ({ commit }, uid) => {
  t.$log(pageName, ' remove', uid)
  return t.$axios.delete(t.$consts.DELETE_NEWS_URL + '/' + uid).then((response) => {
    // (response.code === 201) && commit('remove', uid)
    return (response.code === 201)
  }).catch((error) => {
    t.$log(pageName, ' remove error', error)
    throw error
  })
}

export const update = ({ commit }, payload) => {
  t.$log(pageName, ' update', payload)

  let fd = new FormData()
  fd.append('title', payload.title)
  fd.append('body', payload.body)
  fd.append('assigned_uid', payload.assigned_uid)
  fd.append('commentable', payload.commentable)
  fd.append('display_order', payload.display_order)
  fd.append('publish_at', payload.publish_at)
  fd.append('expires_at', payload.expires_at ? payload.expires_at : '')

  return t.$axios.post(t.$consts.UPDATE_NEWS_URL + '/' + payload.uid, fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
    return response.data
  }).catch((error) => {
    t.$log(pageName, 'update error', error)
    throw error
  })
}
