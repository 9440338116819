import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeAuthActions'

export const login = ({ commit, dispatch }, payload) => {
  return t.$axios.post(t.$consts.AUTH_LOGIN_URL, payload).then((response) => {
    if (response.message === 'recon') {
      return response
    } else {
      t.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.access_token
      commit(t.$mtypes.LOGIN, response)
      dispatch('init')
      return response
    }
  }).catch((error) => {
    throw error
  })
}

export const clearAll = ({ commit }) => {
  commit('announcements/clear', {}, { root: true })
  commit('assignments/clear', {}, { root: true })
  commit('auth/clear', {}, { root: true })
  commit('banners/clear', {}, { root: true })
  commit('bookmarks/clear', {}, { root: true })
  commit('departments/clear', {}, { root: true })
  commit('links/clear', {}, { root: true })
  commit('locationTypes/clear', {}, { root: true })
  commit('locations/clear', {}, { root: true })
  commit('modules/clear', {}, { root: true })
  commit('news/clear', {}, { root: true })
  commit('notifications/clear', {}, { root: true })
  commit('shouts/clear', {}, { root: true })
  commit('themes/clear', {}, { root: true })
  commit('uploads/clear', {}, { root: true })
  commit('users/clear', {}, { root: true })
  commit('submittedContent/clear', {}, { root: true })
}

export const init = ({ dispatch }) => {
  dispatch('banners/fetch', {}, { root: true })
  dispatch('news/fetch', {}, { root: true })
  dispatch('announcements/fetch', {}, { root: true })
  dispatch('uploads/fetch', {}, { root: true })
  dispatch('links/fetch', {}, { root: true })
  dispatch('assignments/fetch', {}, { root: true })
  dispatch('auth/setSearchModal', false, { root: true })
  dispatch('themes/fetch', {}, { root: true })
  dispatch('locations/fetch', {}, { root: true })
  dispatch('departments/fetch', {}, { root: true })
  dispatch('bookmarks/fetch', {}, { root: true })
  dispatch('submittedContent/fetchCount', {}, { root: true })
  dispatch('auth/fetchRoles', {}, { root: true })
}

export const isLoggedIn = ({ state }) => {
  return new Promise((resolve, reject) => {
    t.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.access_token
    t.$axios.get(t.$consts.IS_LOGGED_IN_URL).then((response) => {
      t.$log(pageName, 'auth actions isLoggedIn Response', response)
      resolve(response)
    }).catch((error) => {
      t.$log(pageName, 'auth actions isLoggedIn Error', error)
      reject(error.response)
    })
  })
}

export const logout = ({ commit }) => {
  t.$log(pageName, 'auth actions logout')
  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.LOGOUT_URL).then((response) => {
      t.$log(pageName, 'auth actions logout Response', response)
    }).catch((error) => {
      t.$log(pageName, 'auth actions Logout Error', error)
    }).then(() => {
      commit(t.$mtypes.LOGOUT)
      resolve()
    })
  })
}

export const getUser = ({ commit }) => {
  t.$log(pageName, 'auth actions getUser')
  return new Promise((resolve, reject) => {
    t.$axios.get(t.$consts.GET_USER_URL).then((user) => {
      t.$log(pageName, 'auth actions getUser', user.data)
      commit(t.$mtypes.SET_USER, user.data)
      resolve(user.data)
    }).catch((error) => {
      t.$log(pageName, 'auth actions getUser error', error)
      reject(error.response)
    })
  })
}

export const updateUser = ({ commit }, payload) => {
  t.$log(pageName, 'auth actions updateUser', payload)

  return new Promise((resolve, reject) => {
    t.$axios.patch(t.$consts.UPDATE_USER_URL, payload.user).then((response) => {
      t.$log(pageName, 'auth actions updateUser', response.data)
      commit(t.$mtypes.SET_USER, response.data)
      resolve(response)
    }).catch((error) => {
      t.$log(pageName, 'auth actions updateUser error', error)
      reject(error.response)
    })
  })
}

export const updateCustomerTheme = ({ commit }, payload) => {
  t.$log(pageName, 'auth actions updateCustomerTheme', payload)
  return t.$axios.patch(t.$consts.CUSTOMER_UPDATE_THEME_URL, payload).then((response) => {
    commit('UPDATE_CUSTOMER_THEME', response.data)
    return response.data
  }).catch((error) => {
    throw error
  })
}

export const uploadCustomerFile = ({ state, commit }, payload) => {
  t.$log(pageName, 'auth actions uploadCustomerFile', payload)

  let fd = new FormData()
  fd.append('file', payload.file)
  fd.append('type', payload.type)
  fd.append('size', payload.size)

  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.UPLOAD_CUSTOMER_FILE_URL, fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
      t.$log(pageName, 'auth actions uploadCustomerFile', response)

      resolve(response)
    }).catch((error) => {
      t.$log(pageName, 'auth actions uploadCustomerFile error', error)
      reject(error.response)
    })
  })
}

export const updatePassword = ({ state, commit }, payload) => {
  t.$log(pageName, 'auth actions updatePassword', payload)

  return new Promise((resolve, reject) => {
    t.$axios.put(t.$consts.UPDATE_PASSWORD_URL + '/' + payload.uid, { uid: payload.uid, ...payload.passwords }).then((response) => {
      t.$log(pageName, 'auth actions updatePassword', response.data)
      resolve(response)
    }).catch((error) => {
      t.$log(pageName, 'auth actions updatePassword error', error)
      reject(error.response)
    })
  })
}

export const resetPassword = ({ state, commit }, payload) => {
  t.$log(pageName, 'auth actions resetPassword', payload)

  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.RESET_PASSWORD_URL, payload).then((response) => {
      t.$log(pageName, 'auth actions resetPassword', response.data)
      resolve()
    }).catch((error) => {
      t.$log(pageName, 'auth actions resetPassword error', error)
      reject(error.response)
    })
  })
}

export const resetPasswordUpdate = ({ state, commit }, payload) => {
  t.$log(pageName, 'auth actions resetPasswordUpdate', payload)

  return new Promise((resolve, reject) => {
    t.$axios.patch(t.$consts.RESET_UPDATE_PASSWORD_URL, payload).then((response) => {
      t.$log(pageName, 'auth actions resetPasswordUpdate', response.data)
      resolve()
    }).catch((error) => {
      t.$log(pageName, 'auth actions resetPasswordUpdate error', error)
      reject(error.response)
    })
  })
}

export const uploadAvatar = ({ commit }, payload) => {
  t.$log(pageName, 'auth actions uploadAvatar', payload)

  let fd = new FormData()
  fd.append('file', payload)

  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.UPDATE_AVATAR_URL, fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
      t.$log(pageName, 'auth actions uploadAvatar', response.data)
      commit('UPDATE_AVATAR', response.data)
      resolve()
    }).catch((error) => {
      t.$log(pageName, 'auth actions uploadAvatar error', error)
      reject(error.response)
    })
  })
}

export const addDepartment = ({ state }, payload) => {
  t.$log(pageName, 'auth actions addDepartment', payload)
  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.ADD_DEPARTMENT_URL, payload).then((response) => {
      t.$log(pageName, 'auth actions addDepartment', response)
      resolve(response)
    }).catch((error) => {
      t.$log(pageName, 'auth actions addDepartment error', error)
      reject(error.response)
    })
  })
}

export const getLocationTypes = () => {
  t.$log(pageName, 'auth actions getLocationTypes')
  return new Promise((resolve, reject) => {
    t.$axios.get(t.$consts.GET_LOCATION_TYPES_URL).then((response) => {
      t.$log(pageName, 'auth actions getLocationTypes list Response', response)
      resolve(response.request)
    }).catch((error) => {
      t.$log(pageName, 'auth actions getLocationTypes list Error', error)
      reject(error.response)
    })
  })
}

export const getGlobalLocationTypes = () => {
  t.$log(pageName, 'auth actions getGlobalLocationTypes')
  return new Promise((resolve, reject) => {
    t.$axios.get(t.$consts.GET_GLOBAL_LOCATION_TYPES_URL).then((response) => {
      t.$log(pageName, 'auth actions getGlobalLocationTypes list Response', response)
      resolve(response.request)
    }).catch((error) => {
      t.$log(pageName, 'auth actions getGlobalLocationTypes list Error', error)
      reject(error.response)
    })
  })
}

export const addLocationType = ({ state }, payload) => {
  t.$log(pageName, 'auth actions addLocationType', payload)
  let data = {}
  if (payload.customer_uid) {
    data = payload
  } else {
    data.name = payload
    data.description = 'N/A'
  }
  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.ADD_LOCATION_TYPE_URL, data).then((response) => {
      t.$log(pageName, 'auth actions addLocationType', response)
      resolve(response)
    }).catch((error) => {
      t.$log(pageName, 'auth actions addLocationType error', error)
      reject(error.response)
    })
  })
}

function formatLocationsList (items) {
  let tArr = []
  for (let i = 0; i < items.length; i++) {
    tArr.push(items[i])

    if (!_.isEmpty(items[i].children)) {
      let tChildren = formatLocationsList(items[i].children)
      _.forEach(tChildren, (loc, index) => {
        loc.name = '\xa0\xa0' + loc.name
        tArr.push(loc)
      })
    }
  }
  return tArr
}

export const getGlobalLocations = ({ state }, formatted) => {
  t.$log(pageName, 'auth actions getGlobalLocations')
  return new Promise((resolve, reject) => {
    t.$axios.get(t.$consts.GET_GLOBAL_LOCATIONS_LIST_URL).then((response) => {
      t.$log(pageName, 'auth actions getGlobalLocations list Response', response)

      let tList = response.data
      if (formatted) {
        tList = formatLocationsList(tList)
      }

      resolve(tList)
    }).catch((error) => {
      t.$log(pageName, 'auth actions getGlobalLocations list Error', error)
      reject(error.response)
    })
  })
}

export const addLocation = ({ state }, payload) => {
  t.$log(pageName, 'auth actions addLocation', payload)
  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.ADD_LOCATION_URL, payload).then((response) => {
      t.$log(pageName, 'auth actions addLocation', response)
      resolve(response)
    }).catch((error) => {
      t.$log(pageName, 'auth actions addLocation error', error)
      reject(error.response)
    })
  })
}

export const updateLocation = ({ state }, payload) => {
  t.$log(pageName, 'auth actions updateLocation', payload)
  return new Promise((resolve, reject) => {
    t.$axios.patch(t.$consts.UPDATE_LOCATION_URL + '/' + payload.uid, payload).then((response) => {
      t.$log(pageName, 'auth actions updateLocation', response)
      resolve(response)
    }).catch((error) => {
      t.$log(pageName, 'auth actions updateLocation error', error)
      reject(error.response)
    })
  })
}

export const fetchRoles = ({ commit }) => {
  t.$log(pageName, ' fetchRoles')
  return new Promise((resolve, reject) => {
    t.$axios.get(t.$consts.GET_ROLES_LIST_URL).then((response) => {
      commit('UPDATE_ROLES', response.data)
      resolve()
    }).catch((error) => {
      t.$log(pageName, ' fetch Error', error)
      reject(error.response)
    })
  })
}

export const getRoles = () => {
  t.$log(pageName, 'auth actions getRoles')
  return new Promise((resolve, reject) => {
    t.$axios.get(t.$consts.GET_ROLES_LIST_URL).then((response) => {
      t.$log(pageName, 'auth actions getRoles list Response', response)

      resolve(response.data)
    }).catch((error) => {
      t.$log(pageName, 'auth actions getRoles list Error', error)
      reject(error.response)
    })
  })
}

export const deleteRole = ({ state, commit }, payload) => {
  t.$log(pageName, 'auth actions deleteRole', payload)
  return new Promise((resolve, reject) => {
    t.$axios.delete(t.$consts.DELETE_ROLE_URL + '/' + payload).then((response) => {
      t.$log(pageName, 'auth actions deleteRole', response)
      commit('DELETE_ROLE', payload)
      resolve(response)
    }).catch((error) => {
      t.$log(pageName, 'auth actions deleteRole error', error)
      reject(error.response)
    })
  })
}

export const addRole = ({ state, commit }, payload) => {
  t.$log(pageName, 'auth actions addRole', payload)
  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.ADD_ROLE_URL, payload).then((response) => {
      t.$log(pageName, 'auth actions addRole', response)
      commit('ADD_ROLE', response.data)
      resolve(response)
    }).catch((error) => {
      t.$log(pageName, 'auth actions addRole error', error)
      reject(error.response)
    })
  })
}

export const updateRole = ({ state, commit }, payload) => {
  t.$log(pageName, 'auth actions updateRole', payload)
  return new Promise((resolve, reject) => {
    t.$axios.patch(t.$consts.UPDATE_ROLE_URL + '/' + payload.uid, payload).then((response) => {
      t.$log(pageName, 'auth actions updateRole', response)
      commit('UPDATE_ROLE', response.data)
      resolve(response)
    }).catch((error) => {
      t.$log(pageName, 'auth actions updateRole error', error)
      reject(error.response)
    })
  })
}

export const getPermissions = () => {
  t.$log(pageName, 'auth actions getPermissions')
  return new Promise((resolve, reject) => {
    t.$axios.get(t.$consts.GET_PERMISSIONS_LIST_URL).then((response) => {
      t.$log(pageName, 'auth actions getPermissions list Response', response)

      resolve(response.data)
    }).catch((error) => {
      t.$log(pageName, 'auth actions getPermissions list Error', error)
      reject(error.response)
    })
  })
}

export const addUser = ({ state, commit }, payload) => {
  t.$log(pageName, 'auth actions addUser', payload)

  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.ADD_USER_URL, payload).then((response) => {
      t.$log(pageName, 'auth actions addUser', response)
      resolve(response)
    }).catch((error) => {
      t.$log(pageName, 'auth actions addUser error', error)
      reject(error.response)
    })
  })
}

export const setDataView = ({ state, commit }, payload) => {
  commit('UPDATE_DATA_VIEW_PREFERENCE', payload)
}

export const setSearchModal = ({ commit }, payload) => {
  commit('UPDATE_SHOW_SEARCH_MODAL', payload)
}

export const setSearchResults = ({ commit }, payload) => {
  commit('UPDATE_SEARCH_RESULTS', payload)
}

export const setFilterBy = ({ commit }, payload) => {
  commit('UPDATE_FILTER_BY', payload)
}

export const updateSettings = ({ commit }, payload) => {
  return t.$axios.patch(t.$consts.UPDATE_COMPANY_SETTINGS_URL, payload).then((response) => {
    commit('UPDATE_SETTINGS', payload)
  }).catch((error) => {
    t.$log(pageName, ' fetch Error', error)
    throw error
  })
}

export const updateHomePageFilter = ({ commit }, payload) => {
  return t.$axios.patch(t.$consts.UPDATE_COMPANY_HOME_PAGE_FILTER_URL, { home_page_filter: payload }).then((response) => {
    commit('UPDATE_HOME_PAGE_FILTER', payload)
  }).catch((error) => {
    t.$log(pageName, ' fetch Error', error)
    throw error
  })
}

export const updateCompanyLogo = async ({ commit }, payload) => {
  t.$log(pageName, 'auth actions updateCompanyLogo', payload)

  try {
    await t.$axios.patch(t.$consts.UPDATE_COMPANY_LOGO_URL, { logo: payload })
    commit('UPDATE_COMPANY_LOGO', payload)
    return 'success'
  } catch (e) {
    return e
  }
}

export const updateCompanyTimeZone = ({ commit }, payload) => {
  return t.$axios.patch(t.$consts.UPDATE_COMPANY_TIMEZONE_URL, { timezone: payload }).then(response => {
    commit('UPDATE_TIMEZONE', payload)
  }).catch(error => {
    t.$log(pageName, ' update error', error)
    throw error
  })
}

export const loggingIn = ({ commit }, payload) => {
  commit('IS_LOGGING_IN', payload)
}
