import Vue from 'vue'

export const append = (state, item) => {
  state.data.unshift(item)
}

export const update = (state, data) => {
  Vue.set(state, 'data', data)
}

export const edit = (state, item) => {
  let index = state.data.findIndex(el => el.uid === item.uid)

  Vue.set(state.data, index, item)
}

export const remove = (state, uid) => {
  Vue.delete(state.data, state.data.findIndex(banner => banner.uid === uid))
}

export const clear = (state) => {
  Vue.set(state, 'data', [])
}
