import Vue from 'vue'

export const update = (state, data) => {
  Vue.set(state, 'data', data)
}

export const updateAuthored = (state, data) => {
  Vue.set(state, 'authored', data)
}

export const append = (state, item) => {
  state.authored.unshift(item)
  Vue.set(state, 'authored', state.authored)
}

export const appendReply = (state, item) => {
  for (var i = 0, len = state.data.length; i < len; i++) {
    if (state.data[i].uid === item.uid) {
      Vue.set(state.data, i, item)
    }
  }

  for (var j = 0, leng = state.authored.length; j < leng; j++) {
    if (state.authored[j].uid === item.uid) {
      Vue.set(state.authored, j, item)
    }
  }
}

export const remove = (state, uid) => {
  for (var i = 0, len = state.data.length; i < len; i++) {
    if (uid === state.data[i].uid) {
      Vue.set(state, 'data', state.data.filter(e => e !== state.data[i]))
    }
  }

  for (var j = 0, leng = state.authored.length; j < leng; j++) {
    if (uid === state.data[j].uid) {
      Vue.set(state, 'authored', state.data.filter(e => e !== state.data[j]))
    }
  }
}

export const clear = (state) => {
  Vue.set(state, 'data', [])
  Vue.set(state, 'authored', [])
}
