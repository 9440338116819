export default ({ Vue, store }) => {
  let t = Vue.prototype
  let pageName = 'pluginActions'

  t.$log = function (page, msg, payload, saveToDB) {
    let isPage = false
    let pagesMatch = true

    if (isPage && _.indexOf(isPage, page) === -1) {
      pagesMatch = false
    }

    if (Vue.prototype.$consts.DEBUG && pagesMatch) {
      msg = page + ' - ' + msg
    }

    if (saveToDB) {
      console.log('TODO: Setup this general logger')
    }
  }

  t.$logUser = function (type, message, userUID) {
    t.$log(pageName, 'actions logUser', { type: type, message: message, userUID: userUID })
    return new Promise((resolve, reject) => {
      t.$axios.post(t.$consts.USER_LOG_URL, { type: type, message: message, user_uid: userUID }).then((response) => {
        t.$log(pageName, 'actions logUser Response', response)
        resolve(response)
      }).catch((error) => {
        t.$log(pageName, 'actions logUser Error', error)
        reject(error.response)
      })
    })
  }

  t.$logMedia = function (message, mediaUID, pauseTime, isFinished) {
    t.$log(pageName, 'actions logMedia', { message: message, pauseTime: pauseTime, isFinished: isFinished })
    return new Promise((resolve, reject) => {
      t.$axios.post(t.$consts.MEDIA_LOG_URL, { message: message, media_uid: mediaUID, pause_time: pauseTime, is_finished: isFinished }).then((response) => {
        t.$log(pageName, 'actions logMedia Response', response)
        resolve(response)
      }).catch((error) => {
        t.$log(pageName, 'actions logMedia Error', error)
        reject(error.response)
      })
    })
  }

  t.$logCustomer = function (type, message, customerUID) {
    t.$log(pageName, 'actions logCustomer', { type: type, message: message, customerUID: customerUID })
    return new Promise((resolve, reject) => {
      t.$axios.post(t.$consts.CUSTOMER_LOG_URL, { type: type, message: message, customer_uid: customerUID }).then((response) => {
        t.$log(pageName, 'actions logCustomer Response', response)
        resolve(response)
      }).catch((error) => {
        t.$log(pageName, 'actions logCustomer Error', error)
        reject(error.response)
      })
    })
  }

  t.$sleep = function (time) {
    t.$log(pageName, 'Sleeping: ' + time)
    return new Promise((resolve) => setTimeout(resolve, time))
  }

  t.$findFirstChild = function (element, className) {
    let foundElement = null
    let isClass = true

    if (className.charAt(0) === '#') {
      isClass = false
    }

    className = className.substring(1)

    function recurse (element, className, found) {
      for (var i = 0; i < element.childNodes.length && !found; i++) {
        var el = element.childNodes[i]

        if (isClass) {
          var classes = el.className !== undefined ? el.className.split(' ') : []
          for (var j = 0, jl = classes.length; j < jl; j++) {
            if (classes[j] === className) {
              found = true
              foundElement = element.childNodes[i]
              break
            }
          }
        } else {
          var ids = el.id !== undefined ? el.id.split(' ') : []
          for (var j1 = 0, jl1 = ids.length; j1 < jl1; j1++) {
            if (ids[j1] === className) {
              found = true
              foundElement = element.childNodes[i]
              break
            }
          }
        }

        if (found) {
          break
        }
        recurse(element.childNodes[i], className, found)
      }
    }

    recurse(element, className, false)

    return foundElement
  }

  t.$focusElement = function (elName) {
    document.querySelector(elName).focus()
  }

  t.$scrollToElement = function (elName, mainOptions = { delay: 200, offsetX: 0, offsetY: 0 }) {
    let rect = document.querySelector(elName).getBoundingClientRect()
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop
    let initialY = window.scrollY || window.scrollTop || document.getElementsByTagName('header')[0].scrollTop
    let offset = rect.top + scrollTop - 150 - initialY + mainOptions.offsetY

    let y = initialY + offset
    let baseY = (initialY + y) * 0.5
    let difference = initialY - baseY
    let startTime = performance.now()

    function step () {
      let normalizedTime = (performance.now() - startTime) / mainOptions.delay
      if (normalizedTime > 1) normalizedTime = 1

      window.scrollTo(0, baseY + difference * Math.cos(normalizedTime * Math.PI))
      if (normalizedTime < 1) window.requestAnimationFrame(step)
    }
    window.requestAnimationFrame(step)
  }

  t.$isAllowed = function (requiredPerms, roles) {
    if (!store.getters['auth/accessToken']) {
      return false
    }

    let tPerms = null
    if (roles) {
      tPerms = store.getters['auth/user'].roles
    } else {
      tPerms = store.getters['auth/user'].permissions
    }

    let userPerms = []
    _.forEach(tPerms, (perm, index) => {
      userPerms.push(perm.slug)
    })

    _.remove(requiredPerms, function (n) {
      return n === 'DEFAULT'
    })

    if (requiredPerms[0] === '||') { // Just need one of the permissions
      if (_.difference(requiredPerms, userPerms).length < requiredPerms.length) {
        return true
      }
      return false
    } else { // Require all of the perms passed
      if (_.difference(requiredPerms, userPerms).length === 0) {
        return true
      } else {
        return false
      }
    }
  }

  t.$validateEmail = function (email) {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(String(email).toLowerCase())
  }

  t.$isSameCompany = (owningUid) => {
    return owningUid === store.getters['auth/user'].company.uid
  }

  t.$loading = (isLoading = true, msg = null) => {
    if (isLoading) {
      t.$q.loading.show(
        { message: msg }
      )
    } else {
      t.$q.loading.hide()
    }
  }
}
