import Vue from 'vue'
import { date } from 'quasar'
let t = Vue.prototype
let pageName = 'storeAssignmentsActions'

export const fetch = ({ commit }) => {
  t.$log(pageName, ' fetch')
  return t.$axios.get(t.$consts.GET_ASSIGNMENTS_LIST_URL).then((response) => {
    commit('update', response.request)
  }).catch((error) => {
    t.$log(pageName, ' fetch Error', error)
    throw error
  })
}

export const fetchByUserUid = ({ state }, userUid) => {
  t.$log(pageName, ' fetchByUserUid')
  return t.$axios.get(t.$consts.GET_ASSIGNMENTS_LIST_URL + '?user_uid=' + userUid).then((response) => {
    return response.request
  }).catch((error) => {
    t.$log(pageName, ' fetchByUserUid Error', error)
    throw error
  })
}

export const completedByModuleUid = ({ state, getters, commit }, moduleUid) => {
  if (getters['unfinished'].filter(assignment => assignment.assignable_uid === moduleUid).length === 0) return false

  let assign = getters['unfinished'].filter(assignment => assignment.assignable_uid === moduleUid)[0]
  return t.$axios.post(`${t.$consts.COMPLETE_ASSIGNMENT}/${assign.uid}`).then((response) => {
    if (response.code === 201) {
      commit('update', state.data.map(function (assignment) {
        if (assignment.uid === assign.uid) {
          assignment.completed_at = date.formatDate(Date.now(), 'YYYY/MM/DD')
        }
        return assignment
      }))
      return true
    }
  }).catch((error) => {
    t.$log(pageName, ' fetchByUserUid Error', error)
    throw error
  })
}
