import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeBannersActions'

export const fetch = ({ commit }) => {
  t.$log(pageName, ' fetch')
  return new Promise((resolve, reject) => {
    t.$axios.get(t.$consts.GET_BANNERS_LIST_URL).then((response) => {
      commit('update', response.data)
      resolve()
    }).catch((error) => {
      t.$log(pageName, 'fetch Error', error)
      reject(error.response)
    })
  })
}

export const assign = ({ commit }, payload) => {
  t.$log(pageName, ' update')
  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.ASSIGN_BANNERS_URL, { 'banner': payload }).then((response) => {
      commit('edit', response.data)
      resolve()
    }).catch((error) => {
      t.$log(pageName, 'update Error', error)
      reject(error.response)
    })
  })
}

export const order = ({ commit }, payload) => {
  t.$log(pageName, ' order')
  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.ADD_BANNERS_ORDER_URL, { 'banners': payload }).then((response) => {
      resolve()
    }).catch((error) => {
      t.$log(pageName, 'update Error', error)
      reject(error.response)
    })
  })
}

export const add = ({ state, commit }, payload) => {
  t.$log(pageName, ' add', payload)

  let fd = new FormData()
  fd.append('file', payload.file)
  fd.append('assignedUid', payload.assignedUid)
  fd.append('display_order', payload.display_order)

  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.ADD_BANNER_URL, fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
      commit('append', response.data)
      resolve()
    }).catch((error) => {
      t.$log(pageName, 'add error', error)
      reject(error.response)
    })
  })
}

export const update = ({ state, commit }, payload) => {
  t.$log(pageName, ' add', payload)

  let fd = new FormData()
  if (payload.file) {
    fd.append('file', payload.file)
  }
  fd.append('assignedUid', payload.assignedUid)
  fd.append('display_order', payload.display_order)

  return new Promise((resolve, reject) => {
    t.$axios.post(`${t.$consts.UPDATE_BANNER_URL}/${payload.uid}`, fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
      commit('edit', response.data)
      resolve()
    }).catch((error) => {
      t.$log(pageName, 'add error', error)
      reject(error.response)
    })
  })
}

export const remove = ({ state, commit }, payload) => {
  t.$log(pageName, ' remove', payload)
  return new Promise((resolve, reject) => {
    t.$axios.delete(t.$consts.DELETE_BANNER_URL + '/' + payload).then((response) => {
      commit('remove', payload)
      resolve()
    }).catch((error) => {
      t.$log(pageName, 'remove error', error)
      reject(error.response)
    })
  })
}
