export const getThemes = (state) => state.themes

export const getCustomThemes = (state) => state.themes.filter(theme => theme.customer_uid !== null)

export const getByLabel = (state) => (label) => {
  return state.themes ? state.themes.find(theme => theme.label === label) : null
}

export const isPreviewing = (state) => {
  return state.previewingTheme
}

export const themePreview = (state) => {
  return state.themePreview
}
