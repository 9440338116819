import axios from 'axios'
let pageName = 'pluginsAxios'

export default ({ app, store, router, Vue }) => {
  Vue.prototype.$isUnhandledError = function (error) {
    const handledErrorCodes = [401, 422, 500]

    return !handledErrorCodes.includes(error.response.status)
  }

  Vue.prototype.$axios = axios.create({
    baseURL: (process.env.USE_LOCAL === 'true') ? Vue.prototype.$consts.API_URL_LOCAL : Vue.prototype.$consts.API_URL
  })
  // Global response checker. If we ever get a 401 returned we'll send them to the logout function.
  Vue.prototype.$axios.interceptors.response.use(function (response) {
    Vue.prototype.$log(pageName, 'Axios INTERCEPT', response)
    return response.data
  }, function (error) {
    Vue.prototype.$log(pageName, 'Axios INTERCEPT Error', JSON.parse(JSON.stringify(error)))
    if (error && error.response && error.response.status) {
      if (error.response.status === 422) {
        let data = error.response.data || error.response
        Object.keys(data).forEach(function (item) {
          data[item].forEach(validationError => {
            Vue.prototype.$failureMsg(validationError)
          })
        })
      } else if (error.response.status === 401) {
        Vue.prototype.$failureMsg('Sorry, you are unauthorized to do that. If you feel this is a mistake, please log out and log back in.')
      } else if (error.response.status === 500) {
        Vue.prototype.$failureMsg('Sorry, something went wrong')
      }
    }

    return Promise.reject(error)
  })
}
