import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeModulesActions'

/**
 *
 *      ADDING MORE THAN NORMAL
 */

export const getStoredFiles = ({ state, commit }, payload) => {
  t.$log(pageName, 'manageModules actions getStoredFiles', payload)

  return new Promise((resolve, reject) => {
    let tURL = t.$consts.GET_STORED_FILES_URL
    if (payload && payload.page) {
      tURL += '&page=' + payload.page
    }
    let tType = 'banner'
    if (payload && payload.type) {
      tType = payload.type
    }
    tURL += '&type=' + tType
    t.$axios.get(tURL).then((response) => {
      t.$log(pageName, 'manageModules actions getStoredFiles Response', response)

      let resp = {
        'current_page': response.meta.current_page,
        'pages': response.meta.last_page,
        'images': response.data.request
      }
      resolve(resp)
    }).catch((error) => {
      t.$log(pageName, 'manageModules actions getStoredFiles Error', error)
      reject(error.response)
    })
  })
}

export const deleteStoredFile = ({ state, commit }, file) => {
  t.$log(pageName, 'manageModules actions deleteStoredFile', file)
  return new Promise((resolve, reject) => {
    t.$axios.delete(t.$consts.DELETE_STORED_FILE_URL + '/' + file.uid).then((response) => {
      t.$log(pageName, 'manageModules actions deleteStoredFile', response)
      resolve(response)
    }).catch((error) => {
      t.$log(pageName, 'manageModules actions deleteStoredFile error', error)
      reject(error.response)
    })
  })
}

export const addBookmark = ({ commit }, moduleUid) => {
  t.$log(pageName, ' toggleBookmark', moduleUid)
  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.TOGGLE_MODULE_BOOKMARK_URL + '/' + moduleUid).then((response) => {
      commit('bookmarks/append', moduleUid, { root: true })
      resolve()
    }).catch((error) => {
      t.$log(pageName, ' toggleBookmark error', error)
      reject(error.response)
    })
  })
}

export const removeBookmark = ({ commit }, moduleUid) => {
  t.$log(pageName, ' toggleBookmark', moduleUid)
  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.TOGGLE_MODULE_BOOKMARK_URL + '/' + moduleUid).then((response) => {
      commit('bookmarks/remove', moduleUid, { root: true })
      resolve()
    }).catch((error) => {
      t.$log(pageName, ' toggleBookmark error', error)
      reject(error.response)
    })
  })
}

export const completeModule = ({ commit }, moduleUid) => {
  return new Promise((resolve, reject) => {
    t.$axios.post(t.$consts.COMPLETE_MODULE_URL + '/' + moduleUid).then((response) => {
      resolve()
    }).catch((error) => {
      reject(error.response)
    })
  })
}
