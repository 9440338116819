import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './auth'
import news from './news'
import users from './users'
import links from './links'
import shouts from './shouts'
import uploads from './uploads'
import banners from './banners'
import locations from './locations'
import categories from './categories'
import locationTypes from './locationTypes'
import departments from './departments'
import announcements from './announcements'
import bookmarks from './bookmarks'
import modules from './modules'
import assignments from './assignments'
import notifications from './notifications'
import themes from './themes'
import submittedContent from './submittedContent'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    news,
    users,
    links,
    shouts,
    uploads,
    banners,
    locations,
    categories,
    assignments,
    departments,
    announcements,
    locationTypes,
    bookmarks,
    modules,
    notifications,
    themes,
    submittedContent
  },
  plugins: [createPersistedState()]
})

export default store
