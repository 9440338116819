import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeAnnouncementsActions'

export const fetch = ({ commit }) => {
  t.$log(pageName, ' fetch')
  return t.$axios.get(t.$consts.GET_ANNOUNCEMENTS_URL).then((response) => {
    commit('update', response.data)
  }).catch((error) => {
    t.$log(pageName, 'fetch Error', error)
    throw error
  })
}

export const add = ({ commit }, payload) => {
  t.$log(pageName, ' add', payload)
  return t.$axios.post(t.$consts.ADD_ANNOUNCEMENT_URL, payload).then((response) => {
    commit('append', response.data)
  }).catch((error) => {
    t.$log(pageName, 'newsAnnouoncements actions createAnnouoncement error', error)
    throw error
  })
}

export const remove = ({ commit }, payload) => {
  t.$log(pageName, ' remove', payload)
  return t.$axios.delete(t.$consts.DELETE_ANNOUNCEMENT_URL + '/' + payload).then((response) => {
    // commit('update', response.data)
    commit('remove', payload)
  }).catch((error) => {
    t.$log(pageName, 'remove error', error)
    throw error
  })
}

export const update = ({ commit }, payload) => {
  t.$log(pageName, ' update', payload)

  let fd = new FormData()
  fd.append('title', payload.title)
  fd.append('body', payload.body)
  fd.append('assigned_uid', payload.assigned_uid)
  fd.append('replyable', payload.replyable)
  fd.append('primary_display', Boolean(payload.primary_display))
  fd.append('publish_at', payload.publish_at)
  fd.append('users', JSON.stringify(payload.users))

  return t.$axios.post(t.$consts.UPDATE_ANNOUNCEMENT_URL + '/' + payload.uid, fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
    commit('edit', response.data)
  }).catch((error) => {
    t.$log(pageName, 'update error', error)
    throw error
  })
}
