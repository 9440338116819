export const data = (state) => {
  return state.data
}

export const isByUid = (state) => (uid) => {
  for (var i = 0, len = state.data.length; i < len; i++) {
    if (state.data[i].uid === uid) {
      return true
    }
  }
  return false
}
