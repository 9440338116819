export const data = (state) => {
  return state.data
}

export const getByUid = (state) => (uid) => {
  return state.data.find(location => location.uid === uid)
}

export const list = (state) => {
  let locations = []
  for (var i = 0, len = state.data.length; i < len; i++) {
    if (!state.data[i].is_inactive) {
      locations.push({
        value: state.data[i].uid,
        label: state.data[i].name
      })
    }
  }
  return _.sortBy(locations, ['label'])
}

export const treeListMinusSelf = (state, getters, rootState, rootGetters) => {
  let locations = []
  for (var i = 0, len = state.data.length; i < len; i++) {
    if (!state.data[i].is_inactive && state.data[i].uid !== rootGetters['auth/company'].uid) {
      locations.push({
        id: state.data[i].uid,
        label: state.data[i].name
      })
    }
  }
  return _.sortBy(locations, ['label'])
}

export const treeList = (state) => {
  let locations = []
  for (var i = 0, len = state.data.length; i < len; i++) {
    if (!state.data[i].is_inactive) {
      locations.push({
        id: state.data[i].uid,
        label: state.data[i].name
      })
    }
  }
  return _.sortBy(locations, ['label'])
}

export const inactiveList = (state) => {
  let locations = []
  for (var i = 0, len = state.data.length; i < len; i++) {
    if (state.data[i].is_inactive) {
      locations.push({
        value: state.data[i].uid,
        label: state.data[i].name
      })
    }
  }
  return _.sortBy(locations, ['label'])
}

export const listAll = (state) => {
  let locations = []
  state.data.forEach(location => {
    locations.push({
      value: location.uid,
      label: location.name
    })
  })
  return _.sortBy(locations, ['label'])
}
