import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

export default ({ Vue, store }) => {
  let env = 'local'
  switch (document.domain) {
    case 'lms.msdist.com':
      env = 'prod'
      break
    case 'lms.msdist.dev':
      env = 'dev'
      break
  }

  if (env !== 'local') {
    Sentry.init({
      environment: env,
      dsn: 'https://fbe5a529d1a94a41b0e52d3aa896f566@sentry.io/1425097',
      integrations: [new Integrations.Vue({
        Vue,
        attachProps: true
      })]
    })
  }

  Sentry.configureScope((scope) => {
    scope.setUser({
      'id': store.getters['auth/user'].uid,
      'email': store.getters['auth/user'].email
    })
  })
}
