import Vue from 'vue'
let t = Vue.prototype

export const fetchCount = ({ commit }) => {
  t.$axios.get(t.$consts.COUNT_SUBMITTED_CONTENT_URL).then((response) => {
    commit('updateCount', response.data)
  }).catch((error) => {
    throw error.response
  })
}
