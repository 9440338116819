import algoliasearch from 'algoliasearch'

export default ({ Vue }) => {
  let client = algoliasearch('UDNW5M76TQ', '491dabf2491f0b4a1b6cb485b44fad8b')
  Vue.prototype.$moduleSearch = client

  Vue.prototype.$queryAlgoliaModules = (moduleUid) => {
    client.clearCache()
    return new Promise((resolve, reject) => {
      client.search([{ indexName: Vue.prototype.$consts.MODULES_INDEX, query: moduleUid }], (err, res) => {
        if (err) reject(err)
        resolve(res)
      })
    })
  }
}
