import Vue from 'vue'

export const update = (state, data) => {
  // sorting the assignments from oldest to most recent. Members expect that the first assignment they assign will show up first for the user
  Vue.set(state, 'data', data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)))
}

export const clear = (state) => {
  Vue.set(state, 'data', [])
}
