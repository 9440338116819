export const data = (state) => {
  return state.data
}

export const unfinished = (state) => {
  return state.data.filter(trainingModule => trainingModule.completed_at === null)
}

export const completed = (state) => {
  return state.data.filter(trainingModule => trainingModule.completed_at !== null)
}
