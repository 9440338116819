import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeCategoriesActions'

export const fetch = ({ commit }, type = 'view') => {
  t.$log(pageName, ' fetch')
  return t.$axios.get(`${t.$consts.GET_CATEGORIES_LIST_URL}?type=${type}`).then((response) => {
    return response.request
  }).catch((error) => {
    t.$log(pageName, ' fetch Error', error)
    throw error
  })
}

export const order = ({ commit }, payload) => {
  t.$log(pageName, ' order')
  return t.$axios.post(t.$consts.UPDATE_CATEGORY_ORDER_URL, { 'categories': payload }).then((response) => {
    return response.request
  }).catch((error) => {
    t.$log(pageName, 'update Error', error)
    throw error
  })
}
