import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeUsersActions'

export const fetch = ({ commit }) => {
  t.$log(pageName, ' fetch')
  return t.$axios.get(t.$consts.GET_USERS_URL).then((response) => {
    return response.data
  }).catch((error) => {
    t.$log(pageName, ' fetch Error', error)
    throw error
  })
}

export const fetchList = ({ commit }, locationUid) => {
  let url = locationUid ? `${t.$consts.GET_USERS_LIST_URL}?location=${locationUid}` : t.$consts.GET_USERS_LIST_URL
  return t.$axios.get(url).then((response) => {
    response.data.sort((a, b) => {
      let nameA = a.name.toUpperCase()
      let nameB = b.name.toUpperCase()
      if (nameA < nameB) {
        return -1
      } else if (nameA > nameB) {
        return 1
      }
      return 0
    })
    return response.data
  })
}

export const fetchListByDepartment = ({ commit }, departmentUid) => {
  return t.$axios.get(`${t.$consts.GET_USERS_LIST_BY_DEPARTMENT_URL}?department_uid=${departmentUid}`).then((response) => {
    response.data.sort((a, b) => {
      let nameA = a.name.toUpperCase()
      let nameB = b.name.toUpperCase()
      if (nameA < nameB) {
        return -1
      } else if (nameA > nameB) {
        return 1
      }
      return 0
    })
    return response.data
  })
}

export const fetchFullList = ({ commit }, locationUid) => {
  return t.$axios.get(t.$consts.GET_FULL_USERS_LIST_URL).then((response) => {
    response.data.sort((a, b) => {
      let nameA = a.name.toUpperCase()
      let nameB = b.name.toUpperCase()
      if (nameA < nameB) {
        return -1
      } else if (nameA > nameB) {
        return 1
      }
      return 0
    })
    return response.data
  })
}

export const fetchAdvocateList = () => {
  let usersList = []
  t.$axios.get(t.$consts.GET_ADVOCATES_LIST_URL).then((response) => {
    response.data.forEach(user => {
      usersList.push({
        value: user.uid,
        label: user.first_name + ' ' + user.last_name
      })
    })
    usersList.sort((a, b) => {
      let nameA = a.label.toUpperCase()
      let nameB = b.label.toUpperCase()
      if (nameA < nameB) {
        return -1
      } else if (nameA > nameB) {
        return 1
      }
      return 0
    })
  })
  return usersList
}

export const fetchByUid = ({ commit }, Uid) => {
  t.$log(pageName, ' fetchByUid')
  return t.$axios.get(t.$consts.GET_USER_BY_UID_URL + Uid).then((response) => {
    return response.data
  }).catch((error) => {
    t.$log(pageName, ' fetchByUid Error', error)
    throw error
  })
}

export const update = ({ commit }, payload) => {
  t.$log(pageName, ' update')
  return t.$axios.patch(t.$consts.UPDATE_CUSTOMER_USER_URL + '/' + payload.uid, payload).then((response) => {
    return null
  }).catch((error) => {
    t.$log(pageName, ' update error', error)
    throw error
  })
}

export const toggle = ({ commit }, payload) => {
  t.$log(pageName, ' toggle', payload)
  return t.$axios.put(t.$consts.TOGGLE_CUSTOMER_USER_URL + '/' + payload, payload).then((response) => {
    return null
  }).catch((error) => {
    t.$log(pageName, ' toggle error', error)
    throw error
  })
}

export const remove = ({ commit }, payload) => {
  t.$log(pageName, ' remove', payload)
  return t.$axios.delete(t.$consts.DELETE_CUSTOMER_USER_URL + '/' + payload, payload).then((response) => {
    if (response.status === 200) {
      return null
    }
  }).catch((error) => {
    t.$log(pageName, ' remove error', error)
    throw error
  })
}
