export const data = (state) => {
  return state.data
}

export const filterBy = (state, getters, rootState, rootGetters) => (filterType) => {
  if (filterType === 'Company') {
    return state.data.filter(item => item.assigned_type === 'Company')
  } else if (filterType === 'Department') {
    return state.data.filter(item => rootGetters['auth/userDepartmentUids'].includes(item.assigned_uid))
  } else if (filterType === 'Location') {
    return state.data.filter(item => rootGetters['auth/customerUid'] === item.assigned_uid)
  } else {
    return []
  }
}

export const getByAssignedUid = (state) => (uid) => {
  return state.data.filter(uploads => uploads.assigned_uid === uid)
}
