import Vue from 'vue'
let t = Vue.prototype

export const clear = (state) => {
  Vue.set(state, 'is_logged_in', false)
  Vue.set(state, 'access_token', null)
  Vue.set(state, 'expires_in', null)
  Vue.set(state, 'refresh_token', null)
  Vue.set(state, 'dataView', 'list')
  Vue.set(state, 'filterBy', 'Company')
  Vue.set(state, 'showSearch', false)
  Vue.set(state, 'searchResults', null)
  Vue.set(state, 'roles', {})
  Vue.set(state.user, 'customer', {})
  Vue.set(state.user, 'departments', [])
  Vue.set(state.user, 'company', {})
}

export const LOGIN = (state, payload) => {
  state.is_logged_in = true
  state.access_token = payload.access_token
  state.expires_in = payload.expires_in
  state.refresh_token = payload.refresh_token
}

export const IS_LOGGING_IN = (state, payload) => {
  state.is_logging_in = payload
}

export const LOGOUT = (state) => {
  state.is_logged_in = false
  state.access_token = null
  state.expires_in = null
  state.refresh_token = null
  t.$axios.defaults.headers.common['Authorization'] = ''
}

export const SET_USER = (state, payload) => {
  Object.assign(state.user, payload)
}

export const UPDATE_ROLES = (state, payload) => {
  Vue.set(state, 'roles', payload)
}

export const UPDATE_AVATAR = (state, payload) => {
  Vue.set(state.user, 'avatar', payload.avatar)
}

export const UPDATE_CUSTOMER_THEME = (state, payload) => {
  state.user.company.theme = payload
}

export const UPDATE_DATA_VIEW_PREFERENCE = (state, preference) => {
  state.dataView = preference
}

export const UPDATE_SHOW_SEARCH_MODAL = (state, payload) => {
  state.showSearch = payload
}

export const UPDATE_SEARCH_RESULTS = (state, payload) => {
  state.searchResults = payload
}

export const UPDATE_FILTER_BY = (state, payload) => {
  state.filterBy = payload
}

export const UPDATE_COMPANY_LOGO = (state, payload) => {
  state.user.company.logo = payload
}

export const UPDATE_HOME_PAGE_FILTER = (state, payload) => {
  state.user.company.home_page_filter = payload
}

export const UPDATE_SETTINGS = (state, payload) => {
  state.user.company.settings[payload.type] = payload.value
}

export const UPDATE_TIMEZONE = (state, payload) => {
  state.user.company.timezone = payload
}

export const ADD_ROLE = (state, payload) => {
  state.roles.push(payload)
}

export const UPDATE_ROLE = (state, payload) => {
  let index = state.roles.findIndex(role => role.uid === payload.uid)

  if (index > -1) {
    Vue.set(state.roles, index, payload)
  }
}

export const DELETE_ROLE = (state, payload) => {
  let index = state.roles.findIndex(role => role.uid === payload)

  if (index > -1) {
    Vue.delete(state.roles, index)
  }
}
