export default ({ Vue }) => {
  let REAL_DEBUG = (document.domain !== 'lms.msdist.com')
  let REAL_CLIENT_SECRET = (document.domain === 'lms.msdist.com' || document.domain === 'lmsfrontend-prod2.azurewebsites.net') ? 'C8J92xWHyZMIkYpdyu5iBPPCtjKOa12EiPj5mUwm' : 'mVzlv8JnAxL4RRPS6QwErgvFJYsnc3e5NLfQQVaD'
  let REAL_MODULES_INDEX = (document.domain === 'lms.msdist.com' || document.domain === 'lmsfrontend-prod2.azurewebsites.net') ? 'modules' : 'dev_modules'
  let REAL_ASSIGNS_INDEX = (document.domain === 'lms.msdist.com' || document.domain === 'lmsfrontend-prod2.azurewebsites.net') ? 'assigns' : 'dev_assigns'

  let REAL_API_URL
  let REAL_SAS_STRING
  let REAL_VIDEO_URL

  if (document.domain === 'lms.msdist.com') {
    REAL_API_URL = 'https://lms-api.msdist.com/api/v1'
    REAL_VIDEO_URL = 'https://msdistlmsvideoprod.blob.core.windows.net/'
    REAL_SAS_STRING = '?sv=2019-02-02&ss=bfqt&srt=sco&sp=r&se=2025-01-29T01:25:43Z&st=2020-01-27T17:25:43Z&spr=https,http&sig=itinlqTz%2FM9ossCvXRVWVEMw3crZcrHTbtPAMa4KJ30%3D'
  } else if (document.domain === 'lmsfrontend-prod2.azurewebsites.net') {
    REAL_API_URL = 'https://lmsbackendapi-prod2.azurewebsites.net/api/v1'
    REAL_VIDEO_URL = 'https://msdistlmsvideoprod.blob.core.windows.net/'
    REAL_SAS_STRING = '?sv=2019-02-02&ss=bfqt&srt=sco&sp=r&se=2025-01-29T01:25:43Z&st=2020-01-27T17:25:43Z&spr=https,http&sig=itinlqTz%2FM9ossCvXRVWVEMw3crZcrHTbtPAMa4KJ30%3D'
  } else {
    REAL_API_URL = 'https://lms-api.msdist.dev/api/v1'
    REAL_VIDEO_URL = 'https://msdistlmsvideodev.blob.core.windows.net/'
    REAL_SAS_STRING = '?sv=2019-02-02&ss=bfqt&srt=sco&sp=r&se=2025-01-29T00:32:55Z&st=2020-01-27T16:32:55Z&spr=https,http&sig=LjL4tgrluT2otUTJr3mgvAl4FFABMmmN77IljI04qk8%3D'
  }

  Vue.prototype.$newVersionExists = false

  Vue.prototype.$consts = {
    // General
    DEBUG: REAL_DEBUG,
    API_URL: REAL_API_URL,
    API_URL_LOCAL: 'https://lms-backend.test/api/v1',

    MODULES_INDEX: REAL_MODULES_INDEX,
    ASSIGNS_INDEX: REAL_ASSIGNS_INDEX,
    LMS_VIDEO_URL: REAL_VIDEO_URL,
    SAS_STRING: REAL_SAS_STRING,

    CLIENT_ID: '2',
    CLIENT_SECRET: REAL_CLIENT_SECRET,
    GRANT_TYPE: 'password',

    DEFAULT_AVATAR: 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm',
    // Auth
    // AUTH_LOGIN_URL: '/oauth/token',
    AUTH_LOGIN_URL: '/auth/login',
    LOGOUT_URL: '/auth/logout',
    GET_USER_URL: '/auth/user',
    IS_LOGGED_IN_URL: '/auth/check',
    RESET_PASSWORD_URL: '/auth/reset/store',
    RESET_UPDATE_PASSWORD_URL: '/auth/reset/update',
    UPDATE_AVATAR_URL: '/user/update/avatar',
    GET_USER_NOTIFICATIONS_URL: '/user/notifications',
    MARK_NOTIFICATION_AS_SEEN_URL: '/user/notifications/markSeen',
    UPDATE_USER_URL: '/user/update',
    UPDATE_PASSWORD_URL: '/user/update/password',
    GET_USERS_LIST_URL: '/user/list',
    GET_USERS_LIST_BY_DEPARTMENT_URL: '/user/listByDepartment',
    GET_FULL_USERS_LIST_URL: '/user/fullList',
    GET_ADVOCATES_LIST_URL: '/user/advocates',
    GET_USER_DETAILED_URL: '/user/show',
    COMPLETE_ASSIGNMENT: '/training/assign/complete',
    //

    UPLOAD_CUSTOMER_FILE_URL: '/customer/upload',
    //

    // User Customer
    GET_USERS_URL: '/user/customer?amount=99999',
    GET_USER_BY_UID_URL: '/user/customer/show/',
    ADD_USER_URL: '/user/customer/store',
    UPDATE_CUSTOMER_USER_URL: '/user/customer/update',
    DELETE_CUSTOMER_USER_URL: '/user/customer/destroy',
    TOGGLE_CUSTOMER_USER_URL: '/user/customer/activate',
    CHECK_USER_EXISTS_URL: '/user/customer/exists',
    //

    // Modules
    MODULE_FILE_UPLOAD_URL: '/media/media/store',
    MEDIA_DELETE_URL: '/media/media/destroy',
    GET_CATEGORIES_LIST_URL: '/media/category',
    ADD_CATEGORY_URL: '/media/category/store',
    UPDATE_CATEGORY_ORDER_URL: '/media/category/order',
    UPDATE_CATEGORY_URL: '/media/category/update',
    DELETE_CATEGORY_URL: '/media/category/destroy',
    CREATE_MODULE_URL: '/media/module/store/all',
    UPDATE_MODULE_URL: '/media/module/update',
    DELETE_MODULE_URL: 'media/module/destroy',
    GET_STORED_FILES_URL: '/media/file-storage',
    DELETE_STORED_FILE_URL: '/media/file-storage/destroy',

    GET_ASSIGNS: '/training/assign',
    ADD_ASSIGNMENT_URL: '/training/assign/store',
    REMOVE_ASSIGNMENT_URL: '/training/assign/destroy',
    GET_RECURRING_TRAINING: '/training/recurring',
    ADD_RECURRING_TRAINING: '/training/recurring/store',
    REMOVE_RECURRING_TRAINING: 'training/recurring/destroy',
    POST_QUIZ_RESULTS_URL: '/media/quiz/store',
    GET_LOCKED_QUIZZES_URL: '/media/quiz/locks',
    UNLOCK_QUIZ_URL: '/media/quiz/locks/remove',
    IMPORT_ZIP_MODULE_URL: '/media/module/importZip',
    //

    // module
    GET_MODULE_URL: '/media/module/show',
    GET_MODULE_EDIT_URL: '/media/module/edit',
    GET_GLOBAL_MODULES_LIST_URL: '/media/module/global',
    COMPLETE_MODULE_URL: '/media/module/complete',
    ADD_COMMENT_URL: '/media/discussion/store',
    DELETE_COMMENT_URL: '/media/discussion/destroy',
    ADD_REPLY_URL: '/media/discussion/reply/store',
    DELETE_REPLY_URL: '/media/discussion/reply/destroy',
    GET_IMPORTED_MODULES_LIST_URL: '/media/module/imported',
    GET_IMPORTED_MODULES_REPORT_URL: '/media/module/imported/report',
    //

    // Access
    GET_IP_ACCESS_URL: '/customer/access',
    ADD_IP_ACCESS_URL: '/customer/access/store',
    UPDATE_IP_ACCESS_URL: '/customer/access/update/',
    DELETE_IP_ACCESS_URL: '/customer/access/destroy',
    //

    // Banners
    GET_BANNERS_LIST_URL: '/customer/banner',
    ADD_BANNER_URL: '/customer/banner/store',
    UPDATE_BANNER_URL: '/customer/banner/update',
    DELETE_BANNER_URL: '/customer/banner/destroy',
    ADD_BANNERS_ORDER_URL: '/customer/banner/order',
    ASSIGN_BANNERS_URL: '/customer/banner/assign',
    //

    // Bookmarks
    GET_BOOKMARKS_MODULES_URL: '/auth/bookmarks',
    TOGGLE_MODULE_BOOKMARK_URL: 'media/bookmark/module',
    TOGGLE_COURSE_BOOKMARK_URL: 'media/bookmark/course',
    //

    // Assignments
    GET_ASSIGNMENTS_LIST_URL: '/training/assign',
    //

    // Departments
    GET_DEPARTMENTS_LIST: '/customer/department',
    ADD_DEPARTMENT_URL: '/customer/department/store',
    DELETE_DEPARTMENT_URL: '/customer/department/destroy',
    //

    // Nested Types
    GET_LOCATION_TYPES_URL: '/customer/nested?type=user-all&amount=9999',
    GET_GLOBAL_LOCATION_TYPES_URL: '/customer/nested?type=global',
    ADD_LOCATION_TYPE_URL: '/customer/nested/store',
    DELETE_LOCATION_TYPE_URL: '/customer/nested/destroy',
    //

    // Roles
    GET_ROLES_LIST_URL: '/customer/role?amount=9999',
    ADD_ROLE_URL: '/customer/role/store',
    UPDATE_ROLE_URL: '/customer/role/update',
    DELETE_ROLE_URL: '/customer/role/destroy',
    //

    // News
    ADD_NEWS_URL: '/messages/news/store',
    GET_NEWS_URL: '/messages/news',
    UPDATE_NEWS_URL: '/messages/news/update',
    DELETE_NEWS_URL: '/messages/news/destroy',
    //

    // Announcement
    ADD_ANNOUNCEMENT_URL: '/messages/announcement/store',
    GET_ANNOUNCEMENTS_URL: '/messages/announcement',
    UPDATE_ANNOUNCEMENT_URL: '/messages/announcement/update',
    DELETE_ANNOUNCEMENT_URL: '/messages/announcement/destroy',
    //

    // Links
    GET_LINKS_LIST_URL: '/customer/link',
    ADD_LINK_URL: '/customer/link/store',
    UPDATE_LINK_URL: 'customer/link/update',
    DELETE_LINK_URL: '/customer/link/destroy',
    ASSIGN_LINKS_URL: 'customer/link/assign',
    ADD_LINKS_ORDER_URL: '/customer/link/order',
    //

    // Uploads
    ADD_UPLOADS_URL: '/customer/upload/store',
    DELETE_UPLOAD_URL: '/customer/upload/destroy',
    UPDATE_UPLOAD_URL: '/customer/upload/update',
    GET_UPLOADS_LIST_URL: '/customer/upload?all',
    //

    // User Logger
    USER_LOG_URL: '/logger/user/store',
    GET_USER_LOG_URL: '/logger/user/get',
    USER_LOGGED_IN: 11,
    //

    // Media Logger
    MEDIA_LOG_URL: '/logger/media/store',
    GET_HISTORY_URL: '/logger/media/history',
    //

    // Customer Logger
    ADD_LOCATION_URL: '/customer/store',
    UPDATE_LOCATION_URL: '/customer/update',
    DELETE_LOCATION_URL: '/customer/destroy',
    GET_COMPANY_TREE: 'customer/tree',
    CUSTOMER_LOG_URL: '/logger/customer/store',
    GET_CUSTOMER_URL: '/customer/show',
    GET_PERMISSIONS_LIST_URL: '/customer/permission',
    GET_LOCATIONS_LIST_URL: '/customer?list=user-all',
    GET_GLOBAL_LOCATIONS_LIST_URL: '/customer/global',
    TOGGLE_LOCATION_ACTIVE_URL: '/customer/active',
    CUSTOMER_UPDATE_THEME_URL: 'customer/updateTheme',
    UPDATE_COMPANY_LOGO_URL: 'customer/updateLogo',
    UPDATE_COMPANY_HOME_PAGE_FILTER_URL: 'customer/updateHomePageFilter',
    UPDATE_COMPANY_SETTINGS_URL: 'customer/updateSettings',
    UPDATE_COMPANY_TIMEZONE_URL: 'customer/updateTimeZone',
    ADD_NEW_CUSTOMER_URL: 'customer/new',
    //

    // Messages
    GET_MESSAGES_URL: '/messages',
    ADD_MESSAGE_URL: '/messages/store',
    READ_MESSAGE_URL: '/messages/read',
    SEND_BUG_OR_FEATURE_REQUEST_URL: '/messages/request/send',
    //

    // Themes
    GET_THEMES_URL: '/themes',
    ADD_THEME_URL: '/themes/store',
    UPDATE_THEME_URL: '/themes/update',
    DELETE_THEME_URL: '/themes/destroy',
    //

    // Reports
    GET_REPORT: '/reports',
    SCHEDULED_REPORTS_URL: '/reports/scheduled',
    REPORT_UNSUBSCRIBE_URL: '/email/unsubscribe/report',
    DOWNLOAD_REPORT_URL: '/reports/scheduled/pdf',
    EMAIL_REPORT_URL: '/reports/scheduled/emailReport',
    //

    // Reconnaissance
    FETCH_RECON_USER_URL: '/user/recon/list',
    STORE_RECON_USER_URL: '/user/recon/store',
    DESTROY_RECON_USER_URL: '/user/recon/destroy',
    SEARCH_RECON_USER_URL: '/user/recon/search',
    //

    // User Content
    GET_SUBMITTED_CONTENT_URL: '/media/submittedContent',
    STORE_SUBMITTED_CONTENT_URL: '/media/submittedContent/store',
    DELETE_SUBMITTED_CONTENT_URL: '/media/submittedContent/destroy',
    DOWNLOAD_SUBMITTED_CONTENT_URL: '/media/submittedContent/download',
    COUNT_SUBMITTED_CONTENT_URL: '/media/submittedContent/count',

    // Global Vendor List
    GET_GLOBAL_VENDOR_LIST_URL: '/vendors',
    STORE_GLOBAL_VENDOR_LIST_URL: '/vendors/store',
    UPDATE_GLOBAL_VENDOR_LIST_URL: '/vendors/update',
    DELETE_GLOBAL_VENDOR_LIST_URL: '/vendors/destroy',

    states: [
      // United States
      { value: 'US-AL', label: 'Alabama' },
      { value: 'US-AK', label: 'Alaska' },
      { value: 'US-AZ', label: 'Arizona' },
      { value: 'US-AR', label: 'Arkansas' },
      { value: 'US-CA', label: 'California' },
      { value: 'US-CO', label: 'Colorado' },
      { value: 'US-CT', label: 'Connecticut' },
      { value: 'US-DE', label: 'Delaware' },
      { value: 'US-FL', label: 'Florida' },
      { value: 'US-GA', label: 'Georgia' },
      { value: 'US-HI', label: 'Hawaii' },
      { value: 'US-ID', label: 'Idaho' },
      { value: 'US-IL', label: 'Illinois' },
      { value: 'US-IN', label: 'Indiana' },
      { value: 'US-IA', label: 'Iowa' },
      { value: 'US-KS', label: 'Kansas' },
      { value: 'US-KY', label: 'Kentucky' },
      { value: 'US-LA', label: 'Louisiana' },
      { value: 'US-ME', label: 'Maine' },
      { value: 'US-MD', label: 'Maryland' },
      { value: 'US-MA', label: 'Massachusetts' },
      { value: 'US-MI', label: 'Michigan' },
      { value: 'US-MN', label: 'Minnesota' },
      { value: 'US-MS', label: 'Mississippi' },
      { value: 'US-MO', label: 'Missouri' },
      { value: 'US-MT', label: 'Montana' },
      { value: 'US-NE', label: 'Nebraska' },
      { value: 'US-NV', label: 'Nevada' },
      { value: 'US-NH', label: 'New Hampshire' },
      { value: 'US-NJ', label: 'New Jersey' },
      { value: 'US-NM', label: 'New Mexico' },
      { value: 'US-NY', label: 'New York' },
      { value: 'US-NC', label: 'North Carolina' },
      { value: 'US-ND', label: 'North Dakota' },
      { value: 'US-OH', label: 'Ohio' },
      { value: 'US-OK', label: 'Oklahoma' },
      { value: 'US-OR', label: 'Oregon' },
      { value: 'US-PA', label: 'Pennsylvania' },
      { value: 'US-RI', label: 'Rhode Island' },
      { value: 'US-SC', label: 'South Carolina' },
      { value: 'US-SD', label: 'South Dakota' },
      { value: 'US-TN', label: 'Tennessee' },
      { value: 'US-TX', label: 'Texas' },
      { value: 'US-UT', label: 'Utah' },
      { value: 'US-VT', label: 'Vermont' },
      { value: 'US-VA', label: 'Virginia' },
      { value: 'US-WA', label: 'Washington' },
      { value: 'US-WV', label: 'West Virginia' },
      { value: 'US-WI', label: 'Wisconsin' },
      { value: 'US-WY', label: 'Wyoming' },
      { value: 'CA-ON', label: 'Ontario' },
      { value: 'CA-QC', label: 'Quebec' },
      { value: 'CA-BC', label: 'British Columbia' },
      { value: 'CA-AB', label: 'Alberta' },
      { value: 'CA-MB', label: 'Manitoba' },
      { value: 'CA-SK', label: 'Saskatchewan' },
      { value: 'CA-NS', label: 'Nova Scotia' },
      { value: 'CA-NB', label: 'New Brunswick' },
      { value: 'CA-NL', label: 'Newfoundland and Labrador' },
      { value: 'CA-PE', label: 'Prince Edward Island' },
      { value: 'CA-NT', label: 'Northwest Territories' },
      { value: 'CA-NU', label: 'Nunavut' },
      { value: 'CA-YT', label: 'Yukon' }
    ]
  }
}
