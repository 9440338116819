import IdleVue from 'idle-vue'

export default ({ Vue, store }) => {
  Vue.use(IdleVue, {
    store,
    events: ['keydown', 'mousedown'],
    startAtIdle: false,
    idleTime: 7200000
  })
}
