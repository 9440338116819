import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeDepartmentsActions'

export const fetch = ({ commit }) => {
  t.$log(pageName, ' fetch')
  return t.$axios.get(t.$consts.GET_DEPARTMENTS_LIST).then((response) => {
    commit('update', response.data)
  }).catch((error) => {
    t.$log(pageName, ' fetch Error', error)
    throw error
  })
}

export const remove = ({ commit }, uid) => {
  t.$log(pageName, ' remove', uid)
  return t.$axios.delete(t.$consts.DELETE_DEPARTMENT_URL + '/' + uid).then((response) => {
    if (response.code === 200) {
      commit('remove', uid)
    }
  }).catch((error) => {
    t.$log(pageName, ' remove error', error)
    throw error
  })
}

export const add = ({ commit }, payload) => {
  t.$log(pageName, ' add', payload)
  return t.$axios.post(t.$consts.ADD_DEPARTMENT_URL, payload).then((response) => {
    commit('append', response.data)
  }).catch((error) => {
    t.$log(pageName, ' add error', error)
    throw error
  })
}
