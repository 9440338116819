export default ({ Vue }) => {
  Vue.prototype.$formRules = {
    required: (fieldName, msg) => {
      return val => (val !== null && val !== '' && val !== undefined) || (msg || `${fieldName} is required`)
    },
    minimum: (minNum, msg) => {
      return val => val.length >= minNum || (msg || `Please use minimum ${minNum} characters`)
    },
    maximum: (maxNum, msg) => {
      return val => val.length <= maxNum || (msg || `Please use maximum ${maxNum} characters`)
    },
    confirmPassword: (pass) => {
      return val => val === pass || 'Passwords must match'
    }
  }
}
