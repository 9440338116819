import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeNotificationsActions'

export const fetch = ({ commit }) => {
  t.$log(pageName, ' fetch')
  return t.$axios.get(t.$consts.GET_USER_NOTIFICATIONS_URL).then((response) => {
    commit('update', response.data)
  }).catch((error) => {
    t.$log(pageName, ' fetch Error', error)
    return error
  })
}

export const markAllAsRead = ({ commit }) => {
  t.$log(pageName, ' markAsRead')
  return t.$axios.get(`${t.$consts.MARK_NOTIFICATION_AS_SEEN_URL}`).then((response) => {
    commit('hasReadAll')
  }).catch((error) => {
    t.$log(pageName, ' markAsRead Error', error)
    throw error
  })
}
