
export default [
  {
    path: '/',
    component: () => import('layouts/layout.vue'),
    children: [
      { path: '', name: 'login', component: () => import('pages/login.vue') },
      { path: 'forgot-password/:token', name: 'resetPassword', component: () => import('pages/login.vue') },
      { path: 'reconnaissance', name: 'reconnaissance', component: () => import('pages/login.vue') },
      { path: 'unsubscribe/:reportId', name: 'reportUnsubscribe', component: () => import('pages/reportUnsubscribe.vue') },
      { path: 'home-page', name: 'homePage', component: () => import('pages/homePage.vue'), meta: { requiresAuth: true, requiredPerms: [] } },
      { path: 'my-training', name: 'myTraining', component: () => import('pages/my-training.vue'), meta: { requiresAuth: true, requiredPerms: [] } },
      { path: 'unlock-quizzes', name: 'unlockQuizzes', component: () => import('pages/unlockQuizzes.vue'), meta: { requiresAuth: true, requiredPerms: 'advocate' } },
      { path: 'bookmarks', name: 'bookmarks', component: () => import('pages/bookmarks.vue'), meta: { requiresAuth: true, requiredPerms: [] } },
      { path: 'news/:newsId', name: 'news', component: () => import('pages/news.vue'), meta: { requiresAuth: true, requiredPerms: [] } },
      { path: 'history', name: 'history', component: () => import('pages/history.vue'), meta: { requiresAuth: true, requiredPerms: [] } },
      { path: 'browse/:categoryUid?', name: 'browse', component: () => import('pages/browse.vue'), props: (route) => ({ query: route.query.p }), meta: { requiresAuth: true, requiredPerms: [] } },
      { path: 'profile', name: 'profile', component: () => import('pages/profile.vue'), meta: { requiresAuth: true, requiredPerms: [] } },
      { path: 'submittedContent', name: 'submittedContent', component: () => import('pages/submittedContent.vue'), meta: { requiresAuth: true, requiredPerms: [] } },
      { path: 'reports', name: 'reports', component: () => import('pages/reports.vue'), meta: { requiresAuth: true, requiredPerms: [] } },
      { path: 'reports/:type', name: 'report', component: () => import('pages/report.vue'), meta: { requiresAuth: true, requiredPerms: [] } },
      { path: 'importedModules', name: 'importedModules', component: () => import('pages/importedModulesReport.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-modules'] } },
      { path: 'leaderboards', name: 'leaderboards', component: () => import('pages/leaderboards.vue'), meta: { requiresAuth: true, requiredPerms: [] } },
      { path: 'module/:moduleId', name: 'module', component: () => import('pages/module.vue'), meta: { requiresAuth: true, requiredPerms: [] } },
      { path: 'logout', name: 'logout', component: () => import('pages/logout.vue'), meta: { requiresAuth: true, requiredPerms: [] } },
      { path: 'throwSentryError', name: 'throwSentryError', component: () => import('pages/throwSentryError.vue'), meta: { requiresAuth: true, requiredPerms: [] } }
    ]
  },

  { path: '/version', name: 'version', component: () => import('pages/versionHistory.vue') },

  {
    path: '/messages',
    component: () => import('layouts/layout.vue'),
    children: [
      { path: '/messages/:uid?', name: 'shoutOuts', component: () => import('pages/messages.vue'), meta: { requiresAuth: true, requiredPerms: [] } }
    ]
  },

  {
    path: '/manage',
    component: () => import('layouts/layout.vue'),
    children: [
      { path: 'site', name: 'manageSiteSite', component: () => import('pages/manageSite/site.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-site'] } },
      { path: 'roles', name: 'manageSiteUser', component: () => import('pages/manageSite/roles.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-roles'] } },
      { path: 'review-messages', name: 'manageMessages', component: () => import('pages/manageSite/manageMessages.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-users'] } },
      { path: 'company', name: 'manageSiteCompany', component: () => import('pages/manageSite/company.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-company'] } },
      { path: 'ip-addresses', name: 'manageSiteIPs', component: () => import('pages/manageSite/ipAccess.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-ip-access'] } },
      { path: 'announcements', name: 'manageSiteAnnouncements', component: () => import('pages/manageSite/announcements.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-announcements'] } },
      { path: 'assign-training', name: 'managedAssigned', component: () => import('pages/manageSite/assignTraining.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-assigned-training'] } },
      { path: 'banners', name: 'manageSiteBanners', component: () => import('pages/manageSite/banners.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-banners'] } },
      { path: 'news', name: 'manageSiteNews', component: () => import('pages/manageSite/news.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-news'] } },
      { path: 'links', name: 'manageSiteLinks', component: () => import('pages/manageSite/links.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-links'] } },
      { path: 'uploads', name: 'manageSiteFileUploads', component: () => import('pages/manageSite/uploads.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-uploads'] } },
      { path: 'themes', name: 'manageSiteThemes', component: () => import('pages/manageSite/themes.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-custom-themes'] } },
      { path: 'bugs', name: 'manageSiteBugs', component: () => import('pages/manageSite/bugs.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-bug-requests'] } },
      { path: 'vendors', name: 'manageVendors', component: () => import('pages/manageSite/globalVendorList.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-modules'] } },
      { path: 'import-users', name: 'importUsers', component: () => import('pages/manageSite/importUsers.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-users'] } },
      // { path: 'categories', name: 'manageSiteCategories', component: () => import('pages/manageSite/categories.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-categories'] } },
      {
        path: 'categories',
        name: 'manageSiteCategories',
        component: () => import('pages/manageSite/categories.vue'),
        children: [
          {
            path: 'new',
            name: 'manageCategoriesNew'
          },
          {
            path: 'edit',
            name: 'manageCategoriesEdit'
          }
        ],
        meta: { requiresAuth: true, requiredPerms: ['manage-categories'] }
      },
      // { path: 'users', name: 'manageUsers', component: () => import('pages/manageSite/users.vue'), meta: { requiresAuth: true, requiredPerms: ['manage-users'] } },
      {
        path: 'users',
        name: 'manageUsers',
        component: () => import('pages/manageSite/users.vue'),
        children: [
          {
            path: 'new',
            name: 'manageUsersNew'
          },
          {
            path: 'edit',
            name: 'manageUsersEdit'
          }
        ],
        meta: { requiresAuth: true, requiredPerms: ['manage-users'] }
      },
      {
        path: 'modules',
        name: 'manageModules',
        component: () => import('pages/manageSite/modules.vue'),
        children: [
          {
            path: 'new',
            name: 'manageModulesNew'
          },
          {
            path: 'edit/:uid?',
            name: 'manageModulesEdit'
          },
          {
            path: 'import/:uid?',
            name: 'manageModulesImport'
          }
        ],
        meta: { requiresAuth: true, requiredPerms: ['manage-modules'] }
      }
    ]
  },
  {
    path: '/admin',
    component: () => import('layouts/layout.vue'),
    children: [
      {
        path: 'customers',
        name: 'customers',
        component: () => import('pages/manageUsers/customers.vue'),
        meta: { requiresAuth: true, requiredPerms: ['manage-customers'] }
      },
      {
        path: 'customers/new',
        name: 'customersNew',
        component: () => import('pages/manageUsers/customerForm.vue'),
        meta: { requiresAuth: true, requiredPerms: ['manage-customers'] }
      },
      {
        path: 'customers/edit/:uid',
        name: 'customersEdit',
        component: () => import('pages/manageUsers/customerForm.vue'),
        meta: { requiresAuth: true, requiredPerms: ['manage-customers'] }
      },
      {
        path: 'reconnaissance',
        name: 'manageReconnaissance',
        component: () => import('pages/manageUsers/reconnaissance.vue'),
        meta: { requiresAuth: true, requiredPerms: ['manage-customers'] }
      },
      {
        path: 'modulePreview',
        name: 'modulePreview',
        component: () => import('pages/manageUsers/modulePreview.vue'),
        meta: { requiresAuth: true, requiredPerms: ['manage-customers'] }
      }
    ]
  },

  { path: '/404', name: 'error404', component: () => import('pages/404.vue') },
  { path: '*', component: () => import('pages/404.vue') }
]
