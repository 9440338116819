import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeShoutsActions'

export const fetch = ({ commit }) => {
  t.$log(pageName, ' fetch')
  return t.$axios.get(t.$consts.GET_SHOUT_OUTS_URL).then((response) => {
    commit('update', response.request)
  }).catch((error) => {
    t.$log(pageName, ' fetch error', error)
    throw error
  })
}

export const fetchAuthored = ({ commit }) => {
  t.$log(pageName, ' fetchAuthored')
  return t.$axios.get(t.$consts.GET_SHOUT_OUTS_AUTHORED_URL).then((response) => {
    commit('updateAuthored', response.request)
  }).catch((error) => {
    t.$log(pageName, ' fetch error', error)
    throw error
  })
}

export const add = ({ commit }, payload) => {
  t.$log(pageName, ' add')
  return t.$axios.post(t.$consts.ADD_SHOUT_OUTS_URL, payload).then((response) => {
    commit('append', response)
  }).catch((error) => {
    t.$log(pageName, ' add error', error)
    throw error
  })
}

export const addReply = ({ commit }, payload) => {
  t.$log(pageName, ' addReply')
  return t.$axios.post(t.$consts.ADD_SHOUT_OUTS_REPLY_URL, payload).then((response) => {
    commit('appendReply', response)
  }).catch((error) => {
    t.$log(pageName, ' addReply error', error)
    throw error
  })
}

export const remove = ({ commit }, uid) => {
  t.$log(pageName, ' remove')
  return t.$axios.delete(t.$consts.DELETE_SHOUT_OUT_URL + '/' + uid).then((response) => {
    commit('remove', uid)
  }).catch((error) => {
    t.$log(pageName, ' remove error', error)
    throw error
  })
}
