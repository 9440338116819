import Vue from 'vue'
let t = Vue.prototype
import VueRouter from 'vue-router'
import store from '../store'
import routes from './routes'
let pageName = 'routerIndex'

Vue.use(VueRouter)

const Router = new VueRouter({
  mode: process.env.VUE_ROUTER_MODE,
  base: process.env.VUE_ROUTER_BASE,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
  routes
})

Router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    store.dispatch('auth/clearAll')
  }
  if (to.name !== 'login' && to.name !== 'resetPassword' && to.name !== 'reconnaissance' && to.name !== 'reportUnsubscribe' && to.name !== 'version' && !store.getters['auth/authenticated']) {
    Router.push({ name: 'login' }).catch(err => err)
    t.$failureMsg(`You must login to view that page`)
  }
  next()
})

Router.afterEach((to, from) => {
  if (to.matched.some(record => record.meta.requiresAuth) && to.name !== 'logout') {
    var record = to.matched.find(record => record.meta.requiredPerms)
    if (!record) {
      throw new Error('If requiresAuth is set, you must also use requiredPerms')
    }
    store.dispatch('auth/isLoggedIn').then((response) => {
      t.$log(pageName, 'router isLoggedIn success')
    }).catch((error) => {
      console.log(error)
    }).then(() => {
      return new Promise((resolve, reject) => {
        var perms = record.meta.requiredPerms
        if (t.$isAllowed(perms)) {
          resolve()
        } else {
          reject(new Error('Permission Not Granted'))
        }
        resolve()
      })
    }).then(() => {}).catch((error) => {
      t.$log(pageName, 'Cannot Proceed', error)
      Router.push({ name: 'login' }).catch(err => err)
      t.$failureMsg(`You do not have permission to view that page`)
    })
  }
})

export default Router
