import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeLocationsActions'

export const fetch = ({ commit }) => {
  t.$log(pageName, ' fetch')
  return t.$axios.get(t.$consts.GET_LOCATIONS_LIST_URL).then((response) => {
    commit('update', response.data)
  }).catch((error) => {
    t.$log(pageName, ' fetch Error', error)
    throw error
  })
}

export const add = ({ state, commit }, payload) => {
  t.$log(pageName, ' add', payload)
  return t.$axios.post(t.$consts.ADD_LOCATION_URL, payload).then((response) => {
    commit('append', response.data)
  }).catch(error => {
    t.$log(pageName, ' add error', error)
    throw error
  })
}

export const update = ({ state, commit }, payload) => {
  t.$log(pageName, ' update', payload)
  return t.$axios.patch(t.$consts.UPDATE_LOCATION_URL + '/' + payload.uid, payload).then((response) => {
    commit('edit', response.data)
  }).catch((error) => {
    t.$log(pageName, ' update error', error)
    throw error
  })
}

export const toggleActive = ({ state, commit }, uid) => {
  t.$log(pageName, ' update', uid)
  return t.$axios.put(t.$consts.TOGGLE_LOCATION_ACTIVE_URL + '/' + uid).then((response) => {
    commit('edit', response.data)
  }).catch((error) => {
    t.$log(pageName, ' toggleActive Error', error)
    throw error
  })
}

export const remove = ({ state, commit }, payload) => {
  t.$log(pageName, ' remove', payload)
  return t.$axios.delete(t.$consts.DELETE_LOCATION_URL + '/' + payload).then((response) => {
    commit('remove', payload)
  }).catch((error) => {
    t.$log(pageName, ' remove Error', error)
    throw error
  })
}
