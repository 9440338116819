import Vue from 'vue'

export const update = (state, data) => {
  Vue.set(state, 'data', data)
}

export const append = (state, item) => {
  state.data.unshift(item)
  Vue.set(state, 'data', state.data)
}

export const edit = (state, item) => {
  for (var i = 0, len = state.data.length; i < len; i++) {
    if (state.data[i].uid === item.uid) {
      Vue.set(state.data, i, item)
    }
  }
}

export const remove = (state, item) => {
  Vue.delete(state.data, state.data.findIndex(location => location.uid === item))
}

export const clear = (state) => {
  Vue.set(state, 'data', [])
}
