export const data = (state) => {
  return state.data
}

export const list = (state) => {
  let departments = []
  for (var i = 0, len = state.data.length; i < len; i++) {
    departments.push({
      value: state.data[i].uid,
      label: state.data[i].title
    })
  }
  return _.sortBy(departments, ['label'])
}

export const treelist = (state) => {
  let departments = []
  for (var i = 0, len = state.data.length; i < len; i++) {
    departments.push({
      id: state.data[i].uid,
      label: state.data[i].title
    })
  }
  return _.sortBy(departments, ['label'])
}
