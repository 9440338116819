export const data = (state) => {
  return state.data
}

export const authored = (state) => {
  return state.authored
}

export const getByUid = (state) => (uid) => {
  function recursionByUID (arr, messageUid) {
    let found = null
    for (var i = 0, len = arr.length; i < len; i++) {
      if (arr[i].uid === messageUid) {
        return arr[i]
      } else if (arr[i].children !== undefined) {
        found = recursionByUID(arr[i].children, messageUid)
        if (found) {
          return found
        }
      }
    }
  }
  let results = recursionByUID(state.data, uid)
  if (results === undefined) {
    results = recursionByUID(state.authored, uid)
  }
  return results
}
