import Vue from 'vue'

export const update = (state, data) => {
  Vue.set(state, 'themes', data)
}

export const append = (state, item) => {
  state.themes.unshift(item)
}

export const remove = (state, uid) => {
  let deleteIndex = null
  _.forEach(state.themes, (theme, i) => {
    if (uid === theme.uid) deleteIndex = i
  })
  Vue.delete(state.themes, deleteIndex)
}

export const edit = (state, item) => {
  _.forEach(state.themes, (theme, i) => {
    (item.uid === theme.uid) && Vue.set(state.themes, i, item)
  })
}

export const SET_PREVIEWING_THEME = (state, payload) => {
  state.previewingTheme = payload.previewingTheme
  state.themePreview = payload.theme
}

export const clear = (state) => {
  Vue.set(state, 'previewingTheme', false)
  Vue.set(state, 'themePreview', {})
  Vue.set(state, 'themes', [])
}
