export const data = (state) => {
  return state.data
}

export const list = (state) => {
  let locationTypes = []
  for (var i = 0, len = state.data.length; i < len; i++) {
    locationTypes.push({
      value: state.data[i].uid,
      label: state.data[i].name
    })
  }
  return _.sortBy(locationTypes, ['label'])
}
