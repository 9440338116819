import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeThemesActions'

export const setPreviewingTheme = ({ commit }, payload) => {
  commit('SET_PREVIEWING_THEME', payload)
}

export const fetch = ({ commit }) => {
  t.$log(pageName, ' fetch')
  return t.$axios.get(t.$consts.GET_THEMES_URL).then((response) => {
    commit('update', response.request)
  }).catch((error) => {
    t.$log(pageName, ' fetch Error', error)
    throw error
  })
}

export const remove = ({ commit }, theme) => {
  t.$log(pageName, ' remove', theme.uid)
  return t.$axios.delete(t.$consts.DELETE_THEME_URL + '/' + theme.uid).then((response) => {
    (response.status === 201) && commit('remove', theme.uid)
  }).catch((error) => {
    t.$log(pageName, ' remove error', error)
    throw error
  })
}

export const add = ({ commit }, theme) => {
  t.$log(pageName, ' add', theme)
  return t.$axios.post(t.$consts.ADD_THEME_URL, {
    label: theme.label,
    colors: JSON.stringify(theme.colors)
  }).then((response) => {
    commit('append', response)
  }).catch((error) => {
    t.$log(pageName, 'add error', error)
    throw error
  })
}

export const update = ({ commit }, theme) => {
  t.$log(pageName, ' update', theme)
  return t.$axios.post(`${t.$consts.UPDATE_THEME_URL}/${theme.uid}`, {
    label: theme.label,
    colors: JSON.stringify(theme.colors)
  }).then((response) => {
    commit('edit', response)
  }).catch((error) => {
    t.$log(pageName, ' update Error', error)
    throw error
  })
}
