import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeLinksActions'

export const fetch = ({ commit }) => {
  t.$log(pageName, ' fetch')
  return t.$axios.get(t.$consts.GET_LINKS_LIST_URL).then((response) => {
    commit('update', response.data)
  }).catch((error) => {
    t.$log(pageName, ' fetch Error', error)
    throw error
  })
}

export const assign = ({ commit }, payload) => {
  t.$log(pageName, ' assign')
  return t.$axios.post(t.$consts.ASSIGN_LINKS_URL, { 'link': payload }).then().catch((error) => {
    t.$log(pageName, 'assign Error', error)
    throw error
  })
}

export const order = ({ state, commit }, payload) => {
  t.$log(pageName, ' order', payload)
  return t.$axios.post(t.$consts.ADD_LINKS_ORDER_URL, { 'links': payload }).then().catch((error) => {
    t.$log(pageName, 'order Error', error)
    throw error
  })
}

export const remove = ({ state, commit }, payload) => {
  t.$log(pageName, ' remove', payload)
  return t.$axios.delete(t.$consts.DELETE_LINK_URL + '/' + payload).then((response) => {
    commit('update', response.data)
  }).catch((error) => {
    t.$log(pageName, ' remove Error', error)
    throw error
  })
}

export const add = ({ state, commit }, payload) => {
  t.$log(pageName, ' add', payload)

  let fd = new FormData()
  fd.append('file', payload.file)
  fd.append('link', payload.link)
  fd.append('assigned_uid', payload.assigned_uid)
  fd.append('settings', JSON.stringify(payload.settings))
  fd.append('display_order', payload.display_order)

  return t.$axios.post(t.$consts.ADD_LINK_URL, fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
    commit('append', response.data)
  }).catch((error) => {
    t.$log(pageName, ' add Error', error)
    throw error
  })
}

export const update = ({ state, commit }, payload) => {
  t.$log(pageName, ' update', payload)

  let fd = new FormData()
  fd.append('file', payload.file)
  fd.append('link', payload.link)
  fd.append('assigned_uid', payload.assigned_uid)
  fd.append('settings', JSON.stringify(payload.settings))
  fd.append('display_order', payload.display_order)

  return t.$axios.post(`${t.$consts.UPDATE_LINK_URL}/${payload.uid}`, fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
    commit('edit', response.data)
  }).catch((error) => {
    t.$log(pageName, ' update Error', error)
    throw error
  })
}
