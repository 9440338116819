export const list = () => (users) => {
  let list = []
  for (var i = 0, len = users.length; i < len; i++) {
    list.push({
      value: users[i].uid,
      label: users[i].first_name + ' ' + users[i].last_name
    })
  }
  return _.sortBy(list, ['label'])
}

export const treelist = () => (users) => {
  let treelist = []
  for (var i = 0, len = users.length; i < len; i++) {
    treelist.push({
      id: users[i].uid,
      label: users[i].first_name + ' ' + users[i].last_name
    })
  }
  return _.sortBy(treelist, ['label'])
}

export const activeList = () => (users) => {
  let activeList = []
  for (var i = 0, len = users.length; i < len; i++) {
    if (users[i].is_activated) {
      activeList.push({
        value: users[i].uid,
        label: users[i].first_name + ' ' + users[i].last_name
      })
    }
  }
  return _.sortBy(activeList, ['label'])
}

export const deactivatedList = () => (users) => {
  let deactivatedList = []
  for (var i = 0, len = users.length; i < len; i++) {
    if (!users[i].is_activated) {
      deactivatedList.push({
        value: users[i].uid,
        label: users[i].first_name + ' ' + users[i].last_name
      })
    }
  }
  return _.sortBy(deactivatedList, ['label'])
}

export const advocateList = () => (users) => {
  let advocateList = []
  for (var i = 0, len = users.length; i < len; i++) {
    if (users[i].is_activated) {
      if (users[i].is_advocate) {
        advocateList.push({
          value: users[i].uid,
          label: users[i].first_name + ' ' + users[i].last_name
        })
      }
    }
  }
  return _.sortBy(advocateList, ['label'])
}
