import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeLocationTypesActions'

export const fetch = ({ commit }) => {
  t.$log(pageName, ' fetch')
  return t.$axios.get(t.$consts.GET_LOCATION_TYPES_URL).then((response) => {
    commit('update', response.data)
  }).catch((error) => {
    t.$log(pageName, ' fetch Error', error)
    throw error
  })
}

export const remove = ({ state, commit }, uid) => {
  t.$log(pageName, ' remove', uid)
  return t.$axios.delete(t.$consts.DELETE_LOCATION_TYPE_URL + '/' + uid).then((response) => {
    if (response.code === 200) {
      commit('remove', uid)
    }
  }).catch((error) => {
    t.$log(pageName, ' remove error', error)
    throw error.response
  })
}

export const add = ({ state, commit }, payload) => {
  t.$log(pageName, ' add', payload)
  let data = {}
  if (payload.customer_uid) {
    data = payload
  } else {
    data.name = payload
    data.description = 'N/A'
  }
  return t.$axios.post(t.$consts.ADD_LOCATION_TYPE_URL, data).then((response) => {
    commit('append', response.data)
  }).catch((error) => {
    t.$log(pageName, ' add error', error)
    throw error
  })
}
