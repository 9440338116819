import Vue from 'vue'

export const update = (state, data) => {
  Vue.set(state, 'data', data)
}

export const append = (state, item) => {
  state.data.unshift(item)
}

export const edit = (state, item) => {
  _.forEach(state.data, (link, i) => {
    (item.uid === link.uid) && Vue.set(state.data, i, item)
  })
}

export const clear = (state) => {
  Vue.set(state, 'data', [])
}
