export const authenticated = (state) => {
  return state.is_logged_in
}

export const isLoggingIn = (state) => {
  return state.is_logging_in
}

export const accessToken = (state) => {
  return state.access_token
}

export const user = (state) => {
  return state.user
}

export const userUid = (state) => {
  return state.user.uid
}

export const customer = (state) => {
  return state.user.customer.uid
}

export const homePageFilter = (state) => {
  return state.user.company.home_page_filter
}

export const customerUid = (state) => {
  return state.user.customer.uid
}

export const currentTheme = (state, getters, rootState, rootGetters) => {
  let currentTheme
  if (state.user.company.settings && state.user.company.settings.enableUserTheme) {
    currentTheme = (state.user.theme) ? state.user.theme : state.user.company.theme
  } else {
    currentTheme = state.user.company.theme
  }
  if (currentTheme) {
    if (rootGetters['themes/getThemes'].find(theme => theme.uid === currentTheme.uid)) {
      return rootGetters['themes/getThemes'].find(theme => theme.uid === currentTheme.uid)
    } else {
      return currentTheme
    }
  }
  return null
}

export const company = (state) => {
  return state.user.company
}

export const enableMessages = (state) => {
  return (state.user.company.settings) ? state.user.company.settings.enableMessages : false
}

export const enableSubmittedContent = (state) => {
  return (state.user.company.settings) ? state.user.company.settings.enableSubmittedContent : false
}

export const userDepartments = (state) => {
  return state.user.departments.map(department => {
    return { id: department.uid, label: department.title }
  })
}

export const userDepartmentUids = (state) => {
  return state.user.departments.map(department => {
    return department.uid
  })
}

export const rolesList = (state) => {
  let roles = []
  for (var i = 0, len = state.roles.length; i < len; i++) {
    roles.push({
      value: state.roles[i].uid,
      label: state.roles[i].name,
      slug: state.roles[i].slug
    })
  }
  return _.sortBy(roles, ['label'])
}

export const userPermissions = (state) => {
  return state.user.permissions
}

export const userTheme = (state) => state.user.theme

export const customerTheme = (state) => state.user.company.theme

export const dataView = (state) => {
  return state.dataView
}

export const showSearch = (state) => {
  return state.showSearch
}

export const searchResults = (state) => {
  return state.searchResults
}

export const filterBy = (state) => {
  return state.filterBy
}

export const companyLogo = (state) => state.user.company.logo

export const timezone = (state) => state.user.company.timezone

export const userThemeUid = (state) => {
  return state.user.theme ? state.user.theme.uid : null
}

export const roleByUid = (state) => (roleUid) => state.roles.find(r => r.uid === roleUid)
