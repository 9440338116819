import Vue from 'vue'
let t = Vue.prototype
let pageName = 'storeUploadsActions'

export const fetch = ({ commit }) => {
  t.$log(pageName, ' fetch')
  return t.$axios.get(t.$consts.GET_UPLOADS_LIST_URL).then((response) => {
    commit('update', response.data)
  }).catch((error) => {
    t.$log(pageName, ' fetch Error', error)
    throw error
  })
}

export const add = ({ commit }, payload) => {
  t.$log(pageName, ' add', payload)

  let fd = new FormData()
  fd.append('name', payload.name)
  fd.append('publish_at', payload.publish_at)
  fd.append('expires_at', payload.expires_at ? payload.expires_at : '')
  fd.append('assigned_uid', payload.assigned_uid)
  fd.append('file', payload.file)

  return t.$axios.post(t.$consts.ADD_UPLOADS_URL, fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
    commit('append', response.data)
  }).catch((error) => {
    t.$log(pageName, ' add error', error)
    throw error
  })
}

export const remove = ({ commit }, uid) => {
  t.$log(pageName, ' remove', uid)
  return t.$axios.delete(t.$consts.DELETE_UPLOAD_URL + '/' + uid).then((response) => {
    if (response.code === 201) {
      commit('remove', uid)
    }
  }).catch((error) => {
    t.$log(pageName, 'remove error', error)
    throw error
  })
}

export const update = ({ commit }, payload) => {
  t.$log(pageName, ' update', payload)

  let fd = new FormData()
  fd.append('name', payload.name)
  fd.append('publish_at', payload.publish_at)
  fd.append('expires_at', payload.expires_at ? payload.expires_at : '')
  fd.append('assigned_uid', payload.assigned_uid)

  return t.$axios.post(t.$consts.UPDATE_UPLOAD_URL + '/' + payload.uid, fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
    commit('edit', response.data)
  }).catch((error) => {
    t.$log(pageName, ' update error', error)
    throw error
  })
}
