export default ({ Vue }) => {
  Vue.prototype.$successMsg = (msg) => {
    Vue.prototype.$q.notify({
      color: 'positive',
      icon: 'thumb_up',
      position: 'bottom-left',
      message: (msg !== undefined) ? msg : 'Success'
    })
  }

  Vue.prototype.$failureMsg = (msg) => {
    Vue.prototype.$q.notify({
      color: 'negative',
      icon: 'block',
      position: 'bottom-left',
      message: (msg !== undefined) ? msg : 'Sorry, something went wrong'
    })
  }

  Vue.prototype.$failedValidationMsg = (msg) => {
    Vue.prototype.$q.notify({
      color: 'negative',
      icon: 'block',
      position: 'bottom-left',
      message: (msg !== undefined) ? msg : 'Please provide required information'
    })
  }

  Vue.prototype.$slugify = (text) => {
    const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
    const b = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return text.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c =>
        b.charAt(a.indexOf(c))) // Replace special chars
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }

  Vue.prototype.$isURL = (pUrl) => {
    const urlRegex = /^(?:((?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
    return !!urlRegex.test(pUrl)
  }
}
