import Vue from 'vue'

export const update = (state, data) => {
  Vue.set(state, 'data', data)
}

export const hasRead = (state, payload) => {
  state.data.find(notification => notification.uid === payload.uid).seen = true
}

export const hasReadAll = (state, payload) => {
  state.data.forEach(notification => {
    notification.seen = true
  })
}

export const clear = (state) => {
  Vue.set(state, 'data', [])
}
