import Vue from 'vue'

export const update = (state, data) => {
  Vue.set(state, 'data', data)
}

export const remove = (state, uid) => {
  for (var i = 0, len = state.data.length; i < len; i++) {
    if (uid === state.data[i].uid) {
      Vue.delete(state.data, i)
      break
    }
  }
}

export const clear = (state) => {
  Vue.set(state, 'data', [])
}
