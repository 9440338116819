/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import iconSet from 'quasar/icon-set/material-icons'


import Vue from 'vue'

import {Quasar,QAvatar,QBadge,QBanner,QBreadcrumbs,QBreadcrumbsEl,QBtn,QBtnDropdown,QCarousel,QCarouselSlide,QCarouselControl,QCard,QCardActions,QChatMessage,QCheckbox,QCardSection,QCircularProgress,QChip,QColor,QDate,QDialog,QDrawer,QEditor,QExpansionItem,QField,QFile,QForm,QLayout,QLinearProgress,QHeader,QIcon,QInnerLoading,QInput,QImg,QItem,QItemLabel,QItemSection,QList,QMenu,QOptionGroup,QPage,QPageContainer,QPagination,QPageSticky,QPopupProxy,QRadio,QResizeObserver,QResponsive,QRouteTab,QScrollArea,QSelect,QSeparator,QSkeleton,QSplitter,QStepper,QStep,QStepperNavigation,QTable,QTabs,QTab,QTabPanels,QTabPanel,QTimeline,QTimelineEntry,QTh,QTr,QTd,QToggle,QToolbar,QToolbarTitle,QUploader,QSpinner,QSpinnerGears,QSpinnerHourglass,QTooltip,ClosePopup,GoBack,Ripple,TouchPan,Notify,Loading,Dialog,AppVisibility} from 'quasar'


Vue.use(Quasar, { config: {},iconSet: iconSet,components: {QAvatar,QBadge,QBanner,QBreadcrumbs,QBreadcrumbsEl,QBtn,QBtnDropdown,QCarousel,QCarouselSlide,QCarouselControl,QCard,QCardActions,QChatMessage,QCheckbox,QCardSection,QCircularProgress,QChip,QColor,QDate,QDialog,QDrawer,QEditor,QExpansionItem,QField,QFile,QForm,QLayout,QLinearProgress,QHeader,QIcon,QInnerLoading,QInput,QImg,QItem,QItemLabel,QItemSection,QList,QMenu,QOptionGroup,QPage,QPageContainer,QPagination,QPageSticky,QPopupProxy,QRadio,QResizeObserver,QResponsive,QRouteTab,QScrollArea,QSelect,QSeparator,QSkeleton,QSplitter,QStepper,QStep,QStepperNavigation,QTable,QTabs,QTab,QTabPanels,QTabPanel,QTimeline,QTimelineEntry,QTh,QTr,QTd,QToggle,QToolbar,QToolbarTitle,QUploader,QSpinner,QSpinnerGears,QSpinnerHourglass,QTooltip},directives: {ClosePopup,GoBack,Ripple,TouchPan},plugins: {Notify,Loading,Dialog,AppVisibility} })
