import Vue from 'vue'

export const update = (state, data) => {
  Vue.set(state, 'data', data)
}

export const edit = (state, item) => {
  _.forEach(state.data, (upload, i) => {
    (item.uid === upload.uid) && Vue.set(state.data, i, item)
  })
}

export const remove = (state, uid) => {
  let deleteIndex = null
  _.forEach(state.data, (upload, i) => {
    if (uid === upload.uid) deleteIndex = i
  })
  Vue.delete(state.data, deleteIndex)
}

export const append = (state, item) => {
  state.data.unshift(item)
}

export const clear = (state) => {
  Vue.set(state, 'data', [])
}
