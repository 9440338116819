import { register } from 'register-service-worker'
import { Notify } from 'quasar'
import Vue from 'vue'

register(process.env.SERVICE_WORKER_FILE, {
  updatefound () {
    // starts when it's found a new service worker and has started downloading it
    // console.log('updateFound')
    Vue.prototype.$newVersionExists = true
  },
  updated () {
    // when a new version is ready to activate
    Vue.prototype.$newVersionExists = true
  },
  offline () {
    Notify.create({
      message: 'Looks like you have lost internet connection. You can still use the application, but some parts may not work properly.',
      closeBtn: 'Dismiss'
    })
  },

  error (err) {
    console.error('Error during service worker registration:', err)
  }
})
